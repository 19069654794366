import { ICommission } from "../../components/forms/inputs/commission";
import { IContractForService } from "../../components/forms/me/contractForService";
import { ITermsOfBusiness } from "../../components/forms/me/termsOfBusiness";
import { BookingSubType, BookingType } from "../../_enums/bookingTypes.enum";
import { OccupationTypes } from "../../_enums/occipationTypes.enum";
import { ReferenceTypes } from "../../_enums/referenceTypes.enum";
import { ContactInterface } from "../../_models/data.contacts.model";
import { FieldValidaionModel } from "../../_models/data.fieldValidation.model";
import { IXeroClientContact } from "../../_models/data.xeroContact.model";
import { IInvoice, ILineItem } from "../../_types/xero";
import { FormQuestionModuleType, FormQuestionType, IFormQuestion } from "../../state/context/formQuestions.store";
import { IStudent } from "../../_models/data.student.model";
import { ITuitionReport } from "../../_models/data.timesheet.model";
import { IBranch } from "../../_models/data.branch.model";
import { IUmbrella } from "../../_models/data.umbrella.model";
import { ITarget } from "../../_models/data.target.model";
import { BookingCostsHolidayInterface } from "../../_types/bookings";
import { CandidateConfigOnboardingSteps } from "../../_models/data.candidateConfig.model";
import { IsNonUk, ShowFormQuestion, ValidateFileSize, ValidateFileType } from "../helpers";
import { IRates } from "../../components/forms/me/bookingRatesAmend";
import { RightToWork } from "../../_enums/rightToWork.enum";
import { CandidateEnquiryInterface } from "../../_models/data.candidateEnquiry.model";
import { ClientInterface } from "../../_types/client";
import { ICandidateInterviewQuestions } from "../../_models/data.candidateInterview.model";
import { IsValidTime } from "../../components/forms/inputs/time";
import { RightToWorkItem } from "../../hooks/useCustomerDocuments";

export const IsEmail = (val) => {
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
	if (val) {
		if (reg.exec(val)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const IsProfessionalEmail = (val) => {
	const reg = /^[A-Za-z0-9._%+-]+@(?!(?:gmail|yahoo|hotmail|outlook|live|msn)\.(?:com|co\.uk)$)[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i;
	if (val) {
		if (reg.exec(val)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const IsTelephone = (val) => {
	return true;
	// const reg = /^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/i;
	// if (val) {
	// 	if (reg.exec(val)) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// } else {
	// 	return false;
	// }
};

export const IsWebSite = (val) => {
	const reg = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\/[^\s]*)?$/i;
	if (val) {
		if (reg.exec(val)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const ValidateLogin = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.username) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "usernameError",
				error: "Username is required.",
			})
		);
	}
	if (!DATA.password) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "passwordError",
				error: "Password is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCode = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.code) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "codeError",
				error: "Your 2 Factor code is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateReset1 = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Email is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateReset2 = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.password) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "passwordError",
				error: "Password is required.",
			})
		);
	} else {
		if (DATA.password.length < 8) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "passwordError",
					error: "Password must be at least 8 characters.",
				})
			);
		}
	}
	if (!DATA.confirmPassword) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "confirmPasswordError",
				error: "You must confirm your password.",
			})
		);
	}
	if (DATA.password && DATA.confirmPassword && DATA.password !== DATA.confirmPassword) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "confirmPasswordError",
				error: "You must confirm your password.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateRegister = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Email is required.",
			})
		);
	}
	if (DATA.email && !IsEmail(DATA.email)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (!DATA.username) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "usernameError",
				error: "Username is required.",
			})
		);
	}
	if (!DATA.password) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "passwordError",
				error: "Password is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateRegisterForUpdates = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter your email.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateClient = (DATA: ClientInterface) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Client name is required.",
			})
		);
	}
	if (DATA.email && !IsEmail(DATA.email)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (DATA.website && !IsWebSite(DATA.website)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "websiteError",
				error: "Please enter a valid website url.",
			})
		);
	}
	if (!DATA.address) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "addressLine1Error",
				error: "Address Line 1 is required.",
			})
		);
		errors.push(
			new FieldValidaionModel({
				name: "postcodeError",
				error: "Postcode is required.",
			})
		);
	} else {
		if (!DATA.address.addressLine1) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "addressLine1Error",
					error: "Address Line 1 is required.",
				})
			);
		}
		if (!DATA.address.postcode) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "postcodeError",
					error: "Postcode is required.",
				})
			);
		}
	}
	if (DATA.additionalInstructions) {
		if (DATA.additionalInstructions.length > 500) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "additionalInstructionsError",
					error: "Additional instructions must be less than 500 characters.",
				})
			);
		}
	}
	if (DATA.additionalInstructionsClient) {
		if (DATA.additionalInstructionsClient.length > 500) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "additionalInstructionsClientError",
					error: "Additional instructions must be less than 500 characters.",
				})
			);
		}
	}
	return { valid, errors };
};

export const ValidateClients = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.clients || !DATA.clients.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientsError",
				error: "Make sure a valid clients CSV has been uploaded.",
			})
		);
	}
	if (DATA.clients && DATA.clients.length) {
		let valid = true;
		for (let i = 0, item; (item = DATA.clients[i++]); ) {
			if (!item.name || !item.addressLine1 || !item.postcode) valid = false;
		}
		if (!valid) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "clientsError",
					error: "Make sure all clients have at least a name, address line 1 and postcode.",
				})
			);
		}
	}
	return { valid, errors };
};

export const ValidateCandidates = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.candidates || !DATA.candidates.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "candidatesError",
				error: "Make sure a valid candidates CSV has been uploaded.",
			})
		);
	}
	if (DATA.candidates && DATA.candidates.length) {
		let valid = true;
		for (let i = 0, item; (item = DATA.candidates[i++]); ) {
			if (!item.forename || !item.surname) valid = false;
		}
		if (!valid) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "candidatesError",
					error: "Make sure all candidates have at least a forename and surname.",
				})
			);
		}
	}
	return { valid, errors };
};

export const ValidateContact = (DATA: ContactInterface) => {
	let valid = true,
		errors = [];
	if (!DATA.forename || !DATA.forename.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "forenameError",
				error: "First name is required.",
			})
		);
	}
	// if (!DATA.email || !DATA.email.trim()) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "emailError",
	// 			error: "Email is required.",
	// 		})
	// 	);
	// }
	if (DATA.email && !IsEmail(DATA.email)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (DATA.telephone && !IsTelephone(DATA.telephone)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter a valid telephone.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateQualification = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.type) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "typeError",
				error: "Qualification Type is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateEmployment = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.employerName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "employerNameError",
				error: "Employer Name is required.",
			})
		);
	}
	if (!DATA.dateStarted) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "dateStartedError",
				error: "Started Date is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateReferences = (DATA, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA.referenceForename) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "referenceForenameError",
				error: "Reference First Name is required.",
			})
		);
	}
	if (!DATA.referenceSurname) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "referenceSurnameError",
				error: "Reference Surname is required.",
			})
		);
	}
	if (!DATA.referenceEmail) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "referenceEmailError",
				error: "Reference Email is required.",
			})
		);
	}
	if (DATA.referenceEmail && !IsEmail(DATA.referenceEmail)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "referenceEmailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (
		ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.referencePreventGenericEmail) &&
		(DATA.referenceType == ReferenceTypes.Employment ||
			DATA.referenceType == ReferenceTypes.Academic ||
			DATA.referenceType == ReferenceTypes.Education ||
			DATA.referenceType == ReferenceTypes.Character ||
			DATA.referenceType == ReferenceTypes.Factual) &&
		DATA.referenceEmail &&
		IsEmail(DATA.referenceEmail)
	) {
		if (!IsProfessionalEmail(DATA.referenceEmail)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "referenceEmailError",
					error: "A professional referee email address is required for this reference type.",
				})
			);
		}
	}
	if (!ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingReferenceOptionalTelephone)) {
		if (!DATA.referenceTelephone) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "referenceTelephoneError",
					error: "Reference Telephone is required.",
				})
			);
		}
		if (DATA.referenceTelephone && !IsTelephone(DATA.referenceTelephone)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "referenceTelephoneError",
					error: "Please enter a valid telephone number.",
				})
			);
		}
	}
	if (!DATA.referenceType) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "referenceTypeError",
				error: "Reference Type is required.",
			})
		);
	}
	if (DATA.referenceType) {
		if (DATA.referenceType == ReferenceTypes.Character) {
			if (!DATA.referenceDuration) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "referenceDurationError",
						error: "Duration is required.",
					})
				);
			}
		}
	}
	return { valid, errors };
};

export const ValidateCandidateCreate = (DATA, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA.forename) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "forenameError",
				error: "Candidate first name is required.",
			})
		);
	}
	if (!DATA.surname) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "surnameError",
				error: "Candidate surname is required.",
			})
		);
	}
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Candidate email is required.",
			})
		);
	} else {
		if (!IsEmail(DATA.email)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "emailError",
					error: "Candidate Please enter a valid email address.",
				})
			);
		}
	}
	if (!DATA.status) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "statusError",
				error: "Candidate status is required.",
			})
		);
	}
	if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.candidateSourceRequired)) {
		if (!DATA.source) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "sourceError",
					error: "Candidate source is required.",
				})
			);
		}
	}
	return { valid, errors };
};

export const ValidateStudentCreate = (DATA: IStudent) => {
	let valid = true,
		errors = [];
	if (!DATA.forename) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "forenameError",
				error: "Student first name is required.",
			})
		);
	}
	if (!DATA.surname) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "surnameError",
				error: "Student surname is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCandidateStatusChange = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.status) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "statusError",
				error: "Candidate status is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCandidate = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.contractForServiceName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "contractForServiceNameError",
				error: "Signature is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCandidateStep = (DATA, STEP: CandidateConfigOnboardingSteps, formQuestions: IFormQuestion[], RightToWorkAvailableItems: RightToWorkItem[]) => {
	let valid = true,
		errors = [];
	if (STEP === "contactInformation") {
		if (!DATA.forename) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "forenameError",
					error: "First name is required.",
				})
			);
		}
		if (!DATA.surname) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "surnameError",
					error: "Surname is required.",
				})
			);
		}
		if (!DATA.email) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "emailError",
					error: "Email is required.",
				})
			);
		} else {
			if (!IsEmail(DATA.email)) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "emailError",
						error: "Please enter a valid email address.",
					})
				);
			}
		}
		if (!DATA.dob) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "dobError",
					error: "Date of birth is required.",
				})
			);
		}
		if (!DATA.sex) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "sexError",
					error: "Gender is required.",
				})
			);
		}
		if (!DATA.occupation) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "occupationError",
					error: "Occupation is required.",
				})
			);
		} else {
			if (DATA.occupation === OccupationTypes.Teacher && !DATA.subject) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectError",
						error: "Specialism is required.",
					})
				);
			}
		}
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onlineCheckConsent)) {
			if (DATA.onlineCheckConsent === null || DATA.onlineCheckConsent === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "onlineCheckConsentError",
						error: "Consent to online check response is required.",
					})
				);
			}
		}
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.candidateTitle)) {
			if (!DATA.title) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "titleError",
						error: "Title is required.",
					})
				);
			}
		}
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingNumberRequired)) {
			if (!DATA.mobile) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "mobileError",
						error: "Contact mobile number is required.",
					})
				);
			}
		}
	} else if (STEP === "address") {
		if (!IsNonUk(formQuestions)) {
			if (!DATA.address) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "addressLine1Error",
						error: "Address Line 1 is required.",
					})
				);
				errors.push(
					new FieldValidaionModel({
						name: "postcodeError",
						error: "Postcode is required.",
					})
				);
			} else {
				if (!DATA.address.addressLine1) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "addressLine1Error",
							error: "Address Line 1 is required.",
						})
					);
				}
				if (!DATA.address.postcode) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "postcodeError",
							error: "Postcode is required.",
						})
					);
				}
			}
		} else {
			if (!DATA.address) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "countryError",
						error: "Country is required.",
					})
				);
			} else {
				if (!DATA.address.country) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "countryError",
							error: "Country is required.",
						})
					);
				}
			}
		}
	} else if (STEP === "nextOfKin") {
		if (!DATA.nextOfKin) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "nextOfKinNameError",
					error: "Emergency contact name is required.",
				})
			);
		} else {
			if (!DATA.nextOfKin.nextOfKinName) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "nextOfKinNameError",
						error: "Emergency contact name is required.",
					})
				);
			}
			if (!DATA.nextOfKin.nextOfKinTelephone) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "nextOfKinTelephoneError",
						error: "Emergency contact telephone is required.",
					})
				);
			} else {
				if (!IsTelephone(DATA.nextOfKin.nextOfKinTelephone)) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "nextOfKinTelephoneError",
							error: "Please enter a valid telephone number.",
						})
					);
				}
			}
		}
	} else if (STEP === "employmentHistory") {
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingEmploymentHistoryRequired)) {
			if (!DATA.employment?.length) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "employmentError",
						error: "Employment history is required.",
					})
				);
			}
		}
	} else if (STEP === "references") {
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingSingleReference) && (!DATA.references || !DATA.references.length)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "referencesError",
					error: "At least 1 reference is required.",
				})
			);
		}
		if (
			!ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingSingleReference) &&
			(!DATA.references || !DATA.references.length || DATA.references.length < 2)
		) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "referencesError",
					error: "At least 2 references are required.",
				})
			);
		}
		if (!DATA.referenceCheckConsentName) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "referenceCheckConsentNameError",
					error: "Signature is required.",
				})
			);
		}
	} else if (STEP === "criminalRecord") {
		if (!DATA.criminalRecord) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "dbsHeldError",
					error: "DBS held is required.",
				})
			);
			if (!IsNonUk(formQuestions)) {
				errors.push(
					new FieldValidaionModel({
						name: "dbsLastCheckedError",
						error: "DBS certificate issue date is required.",
					})
				);
			}
			errors.push(
				new FieldValidaionModel({
					name: "convictedOffenceError",
					error: "Criminal offences is required.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "convictionsError",
					error: "Criminal convictions is required.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "abroadError",
					error: "Have you been abroad response is required.",
				})
			);
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.educationalDisciplinaryProceedings)) {
				errors.push(
					new FieldValidaionModel({
						name: "educationalDisciplinaryProceedingsError",
						error: "Educational disciplinary proceedings response required.",
					})
				);
			}
		} else {
			if (DATA.criminalRecord.dbsHeld === null || DATA.criminalRecord.dbsHeld === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "dbsHeldError",
						error: "DBS held is required.",
					})
				);
			}
			if (!IsNonUk(formQuestions)) {
				if (DATA.criminalRecord.dbsHeld && !DATA.criminalRecord.dbsNumber) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "dbsNumberError",
							error: "DBS number is required.",
						})
					);
				}
				if (DATA.criminalRecord.dbsHeld) {
					if (!DATA.criminalRecord.dbsLastChecked && !DATA.criminalRecord.dbsUpdateServiceNumber) {
						valid = false;
						errors.push(
							new FieldValidaionModel({
								name: "dbsLastCheckedError",
								error: "DBS certificate issue date or update service number is required.",
							})
						);
						errors.push(
							new FieldValidaionModel({
								name: "dbsUpdateServiceNumberError",
								error: "DBS certificate issue date or update service number is required.",
							})
						);
					}
					if (DATA.criminalRecord?.registeredToUpdateService === null || DATA.criminalRecord?.registeredToUpdateService === undefined) {
						valid = false;
						errors.push(
							new FieldValidaionModel({
								name: "registeredToUpdateServiceError",
								error: "Is the DBS record registered to the update service is required.",
							})
						);
					}
					if (DATA.criminalRecord?.registeredToUpdateService && (DATA.criminalRecord?.consentUpdateServiceChecks === null || DATA.criminalRecord?.consentUpdateServiceChecks === undefined)) {
						valid = false;
						errors.push(
							new FieldValidaionModel({
								name: "consentUpdateServiceChecksError",
								error: "Update service consent response is required.",
							})
						);
					}
				}
			}
			if (DATA.criminalRecord.convictedOffence === null || DATA.criminalRecord.convictedOffence === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "convictedOffenceError",
						error: "Criminal offences is required.",
					})
				);
			}
			if (DATA.criminalRecord.convictions === null || DATA.criminalRecord.convictions === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "convictionsError",
						error: "Criminal convictions is required.",
					})
				);
			}
			if (DATA.criminalRecord.convictions && !DATA.criminalRecord.convictionsDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "convictionsDescriptionError",
						error: "Details of past criminal convictions is required.",
					})
				);
			}
			if (DATA.criminalRecord.convictedOffence && !DATA.criminalRecord.convictedOffenceDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "convictedOffenceDescriptionError",
						error: "Details of criminal offence is required.",
					})
				);
			}
			if (DATA.criminalRecord.abroad === null || DATA.criminalRecord.abroad === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "abroadError",
						error: "Have you been abroad response is required.",
					})
				);
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.educationalDisciplinaryProceedings)) {
				if (DATA.criminalRecord.educationalDisciplinaryProceedings === null || DATA.criminalRecord.educationalDisciplinaryProceedings === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "educationalDisciplinaryProceedingsError",
							error: "Educational disciplinary proceedings response required.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingOverseasOffence)) {
				if (DATA.criminalRecord.overseasOffence === null || DATA.criminalRecord.overseasOffence === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "overseasOffenceError",
							error: "Have you committed an offence overseas which would have resulted in disqualification response is required.",
						})
					);
				}
				if (DATA.criminalRecord?.overseasOffence && !DATA.criminalRecord?.overseasOffenceDescription) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "overseasOffenceDescriptionError",
							error: "Further information related to the offence overseas is required.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.currentCriminalOffence)) {
				if (DATA.criminalRecord?.currentCriminalOffence === null || DATA.criminalRecord?.currentCriminalOffence === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "currentCriminalOffenceError",
							error: "Are you currently under investigation for any criminal offence response is required.",
						})
					);
				}
				if (DATA.criminalRecord?.currentCriminalOffence && !DATA.criminalRecord?.currentCriminalOffenceDescription) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "currentCriminalOffenceDescriptionError",
							error: "Further information related to the current offence is required.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.overseasDeclarationExtended) && DATA.criminalRecord.abroad) {
				if (!DATA.criminalRecord.overseasDeclarationExtended) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "overseasDeclarationExtendedError",
							error: "Please declare the country you resided and any additional useful information.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.dbsDeclarationRead)) {
				if (DATA.criminalRecord.dbsDeclarationRead === null || DATA.criminalRecord.dbsDeclarationRead === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "dbsDeclarationReadError",
							error: "Please declare your read status for the Standard/Enhanced DBS Check Privacy Policy.",
						})
					);
				}
			}
		}
	} else if (STEP === "safeInEducation") {
		if (!DATA.safeInEducation) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "barredError",
					error: "Either yes or no must be selected.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "waiverError",
					error: "Either yes or no must be selected.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "ordersYouError",
					error: "Either yes or no must be selected.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "ordersChildError",
					error: "Either yes or no must be selected.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "previousSafeguardingAllegationsError",
					error: "Either yes or no must be selected.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "cautionedError",
					error: "Either yes or no must be selected.",
				})
			);
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.keepingChildrenSafeExtendedCaution)) {
				errors.push(
					new FieldValidaionModel({
						name: "violentOffenceError",
						error: "Either yes or no must be selected.",
					})
				);
				errors.push(
					new FieldValidaionModel({
						name: "anotherCountryError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.keepingChildrenSafeKCSIE)) {
				errors.push(
					new FieldValidaionModel({
						name: "readKCSIEError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.KeepingChildrenSafeProhibited)) {
				errors.push(
					new FieldValidaionModel({
						name: "prohibitedFromTeachingError",
						error: "Either yes or no must be selected.",
					})
				);
				errors.push(
					new FieldValidaionModel({
						name: "knownPoliceError",
						error: "Either yes or no must be selected.",
					})
				);
				errors.push(
					new FieldValidaionModel({
						name: "prohibitedFromManagementError",
						error: "Either yes or no must be selected.",
					})
				);
				errors.push(
					new FieldValidaionModel({
						name: "criminalOffencesLawError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			errors.push(
				new FieldValidaionModel({
					name: "safeInEducationDetailsError",
					error: "Either yes or no must be selected.",
				})
			);
		} else {
			if (DATA.safeInEducation.barred === null || DATA.safeInEducation.barred === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "barredError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (DATA.safeInEducation.waiver === null || DATA.safeInEducation.waiver === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "waiverError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (DATA.safeInEducation.ordersYou === null || DATA.safeInEducation.ordersYou === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "ordersYouError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (DATA.safeInEducation.ordersChild === null || DATA.safeInEducation.ordersChild === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "ordersChildError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (DATA.safeInEducation.previousSafeguardingAllegations === null || DATA.safeInEducation.previousSafeguardingAllegations === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "previousSafeguardingAllegationsError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (DATA.safeInEducation.cautioned === null || DATA.safeInEducation.cautioned === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "cautionedError",
						error: "Either yes or no must be selected.",
					})
				);
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.keepingChildrenSafeExtendedCaution)) {
				if (DATA.safeInEducation.violentOffence === null || DATA.safeInEducation.violentOffence === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "violentOffenceError",
							error: "Either yes or no must be selected.",
						})
					);
				}
				if (DATA.safeInEducation.anotherCountry === null || DATA.safeInEducation.anotherCountry === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "anotherCountryError",
							error: "Either yes or no must be selected.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.keepingChildrenSafeKCSIE)) {
				if (DATA.safeInEducation.readKCSIE === null || DATA.safeInEducation.readKCSIE === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "readKCSIEError",
							error: "Either yes or no must be selected.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.KeepingChildrenSafeProhibited)) {
				if (DATA.safeInEducation.prohibitedFromTeaching === null || DATA.safeInEducation.prohibitedFromTeaching === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "prohibitedFromTeachingError",
							error: "Either yes or no must be selected.",
						})
					);
				}
				if (DATA.safeInEducation.knownPolice === null || DATA.safeInEducation.knownPolice === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "knownPoliceError",
							error: "Either yes or no must be selected.",
						})
					);
				}
				if (DATA.safeInEducation.prohibitedFromManagement === null || DATA.safeInEducation.prohibitedFromManagement === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "prohibitedFromManagementError",
							error: "Either yes or no must be selected.",
						})
					);
				}
				if (DATA.safeInEducation.criminalOffencesLaw === null || DATA.safeInEducation.criminalOffencesLaw === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "criminalOffencesLawError",
							error: "Either yes or no must be selected.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.keepingChildrenSafeExtendedCaution)) {
				if (
					(DATA.safeInEducation.barred ||
						DATA.safeInEducation.waiver ||
						DATA.safeInEducation.ordersYou ||
						DATA.safeInEducation.ordersChild ||
						DATA.safeInEducation.cautioned ||
						DATA.safeInEducation.violentOffence ||
						DATA.safeInEducation.anotherCountry) &&
					!DATA.safeInEducation.safeInEducationDetails
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "safeInEducationDetailsError",
							error: "Further details are required.",
						})
					);
				}
			} else {
				if (
					(DATA.safeInEducation.barred ||
						DATA.safeInEducation.waiver ||
						DATA.safeInEducation.ordersYou ||
						DATA.safeInEducation.ordersChild ||
						DATA.safeInEducation.previousSafeguardingAllegations ||
						DATA.safeInEducation.cautioned) &&
					!DATA.safeInEducation.safeInEducationDetails
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "safeInEducationDetailsError",
							error: "Further details are required.",
						})
					);
				}
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.KeepingChildrenSafeProhibited)) {
				if (
					(DATA.safeInEducation.prohibitedFromTeaching || DATA.safeInEducation.knownPolice || DATA.safeInEducation.prohibitedFromManagement || DATA.safeInEducation.criminalOffencesLaw) &&
					!DATA.safeInEducation.safeInEducationDetails
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "safeInEducationDetailsError",
							error: "Further details are required.",
						})
					);
				}
			}
			if (!DATA.safeInEducationCheckConsentName) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "safeInEducationCheckConsentNameError",
						error: "Signature is required.",
					})
				);
			}
			if (formQuestions?.find((question) => question.type === FormQuestionModuleType.candidate && question.question === FormQuestionType.safeQuardingQuestionaire)) {
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.primaryPurposeSafeguarding ||
					DATA?.safeInEducation.safeguardingQuestionaire?.primaryPurposeSafeguarding === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.primaryPurposeSafeguarding === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "primaryPurposeSafeguardingError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.typicallyResponsibleCoordinatingSafeguarding ||
					DATA?.safeInEducation.safeguardingQuestionaire?.typicallyResponsibleCoordinatingSafeguarding === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.typicallyResponsibleCoordinatingSafeguarding === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "typicallyResponsibleCoordinatingSafeguardingError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.signOfAbuse ||
					DATA?.safeInEducation.safeguardingQuestionaire?.signOfAbuse === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.signOfAbuse === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "signOfAbuseError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.studentDisclosesInformation ||
					DATA?.safeInEducation.safeguardingQuestionaire?.studentDisclosesInformation === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.studentDisclosesInformation === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "studentDisclosesInformationError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.schoolsChildProtectionPolicy ||
					DATA?.safeInEducation.safeguardingQuestionaire?.schoolsChildProtectionPolicy === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.schoolsChildProtectionPolicy === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "schoolsChildProtectionPolicyError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.fgmDefinition ||
					DATA?.safeInEducation.safeguardingQuestionaire?.fgmDefinition === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.fgmDefinition === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "fgmDefinitionError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.keyPrincipleOnlineSafetyStudents ||
					DATA?.safeInEducation.safeguardingQuestionaire?.keyPrincipleOnlineSafetyStudents === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.keyPrincipleOnlineSafetyStudents === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "keyPrincipleOnlineSafetyStudentsError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.teachersConsiderRegardingSafeguarding ||
					DATA?.safeInEducation.safeguardingQuestionaire?.teachersConsiderRegardingSafeguarding === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.teachersConsiderRegardingSafeguarding === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "teachersConsiderRegardingSafeguardingError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.criminalBackgroundCheckTeachers ||
					DATA?.safeInEducation.safeguardingQuestionaire?.criminalBackgroundCheckTeachers === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.criminalBackgroundCheckTeachers === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "criminalBackgroundCheckTeachersError",
							error: "Answer must be selected.",
						})
					);
				}
				if (
					!DATA?.safeInEducation.safeguardingQuestionaire?.studentConfides ||
					DATA?.safeInEducation.safeguardingQuestionaire?.studentConfides === null ||
					DATA?.safeInEducation.safeguardingQuestionaire?.studentConfides === undefined
				) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "studentConfidesError",
							error: "Answer must be selected.",
						})
					);
				}
			}
		}
	} else if (STEP === "healthRecord") {
		if (!ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.healthAndDisability)) {
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.healthDeclarationConcise)) {
				if (!DATA.healthRecord) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "healthIssuesError",
							error: "You must complete the health declaration.",
						})
					);
				} else {
					if (
						DATA.healthRecord.affectAbility === null ||
						DATA.healthRecord.affectAbility === undefined ||
						DATA.healthRecord.notificationAgree === null ||
						DATA.healthRecord.notificationAgree === undefined
					) {
						valid = false;
						errors.push(
							new FieldValidaionModel({
								name: "healthIssuesError",
								error: "You must provide an answer to each of the above health declaration questions.",
							})
						);
					}
					if (DATA.healthRecord.affectAbility && !DATA.healthRecord.healthIssuesDescription) {
						valid = false;
						errors.push(
							new FieldValidaionModel({
								name: "healthIssuesDescriptionError",
								error: "You must provide further details regardling your health declartion answers above.",
							})
						);
					}
				}
			} else {
				if (!DATA.healthRecord) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "healthIssuesError",
							error: "You must complete the health declaration.",
						})
					);
				} else {
					if (
						DATA.healthRecord.physicalAbilityCondition === null ||
						DATA.healthRecord.physicalAbilityCondition === undefined ||
						DATA.healthRecord.confused === null ||
						DATA.healthRecord.confused === undefined ||
						DATA.healthRecord.hearing === null ||
						DATA.healthRecord.hearing === undefined ||
						DATA.healthRecord.eyesight === null ||
						DATA.healthRecord.eyesight === undefined ||
						DATA.healthRecord.depression === null ||
						DATA.healthRecord.depression === undefined ||
						DATA.healthRecord.severePain === null ||
						DATA.healthRecord.severePain === undefined ||
						DATA.healthRecord.excessiveDrowsiness === null ||
						DATA.healthRecord.excessiveDrowsiness === undefined ||
						DATA.healthRecord.epilepsy === null ||
						DATA.healthRecord.epilepsy === undefined ||
						DATA.healthRecord.heart === null ||
						DATA.healthRecord.heart === undefined ||
						DATA.healthRecord.asthma === null ||
						DATA.healthRecord.asthma === undefined ||
						DATA.healthRecord.mentalHealth === null ||
						DATA.healthRecord.mentalHealth === undefined ||
						DATA.healthRecord.allergies === null ||
						DATA.healthRecord.allergies === undefined ||
						(formQuestions?.find((question) => question.type === FormQuestionModuleType.candidate && question.question === FormQuestionType.healthDeclaration) &&
							(DATA.healthRecord.contagiousDiseases === null || DATA.healthRecord.contagiousDiseases === undefined))
					) {
						valid = false;
						errors.push(
							new FieldValidaionModel({
								name: "healthIssuesError",
								error: "You must provide an answer to each of the above health declaration questions.",
							})
						);
					}
					if (!ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingMedicalRequirements)) {
						if (DATA.healthRecord.medication === null || DATA.healthRecord.medication === undefined) {
							valid = false;
							errors.push(
								new FieldValidaionModel({
									name: "healthIssuesError",
									error: "You must provide an answer to each of the above health declaration questions.",
								})
							);
						}
					}
					if (
						(DATA.healthRecord.physicalAbilityCondition ||
							DATA.healthRecord.confused ||
							DATA.healthRecord.hearing ||
							DATA.healthRecord.eyesight ||
							DATA.healthRecord.depression ||
							DATA.healthRecord.severePain ||
							DATA.healthRecord.excessiveDrowsiness ||
							DATA.healthRecord.epilepsy ||
							DATA.healthRecord.heart ||
							DATA.healthRecord.asthma ||
							DATA.healthRecord.mentalHealth ||
							DATA.healthRecord.allergies ||
							DATA.healthRecord.medication ||
							(formQuestions?.find((question) => question.type === FormQuestionModuleType.candidate && question.question === FormQuestionType.healthDeclaration) &&
								DATA.healthRecord.contagiousDiseases)) &&
						!DATA.healthRecord.healthIssuesDescription
					) {
						valid = false;
						errors.push(
							new FieldValidaionModel({
								name: "healthIssuesDescriptionError",
								error: "You must provide further details regardling your health declartion answers above.",
							})
						);
					}
				}
			}
		}
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.medicalHealthDeclaration)) {
			if (
				DATA.healthRecord?.vaccinationMeasles === null ||
				DATA.healthRecord?.vaccinationMeasles === undefined ||
				DATA.healthRecord?.vaccinationMumps === null ||
				DATA.healthRecord?.vaccinationMumps === undefined ||
				DATA.healthRecord?.vaccinationRubella === null ||
				DATA.healthRecord?.vaccinationRubella === undefined ||
				DATA.healthRecord?.vaccinationVaricella === null ||
				DATA.healthRecord?.vaccinationVaricella === undefined ||
				DATA.healthRecord?.vaccinationTuberculosis === null ||
				DATA.healthRecord?.vaccinationTuberculosis === undefined ||
				DATA.healthRecord?.vaccinationHepatitisB === null ||
				DATA.healthRecord?.vaccinationHepatitisB === undefined ||
				DATA.healthRecord?.vaccinationHepatitisC === null ||
				DATA.healthRecord?.vaccinationHepatitisC === undefined ||
				DATA.healthRecord?.vaccinationHIV === null ||
				DATA.healthRecord?.vaccinationHIV === undefined ||
				DATA.healthRecord?.vaccinationCovid === null ||
				DATA.healthRecord?.vaccinationCovid === undefined ||
				DATA.healthRecord?.vaccinationCovid1 === null ||
				DATA.healthRecord?.vaccinationCovid1 === undefined ||
				DATA.healthRecord?.vaccinationCovid2 === null ||
				DATA.healthRecord?.vaccinationCovid2 === undefined ||
				DATA.healthRecord?.vaccinationCovidBooster === null ||
				DATA.healthRecord?.vaccinationCovidBooster === undefined ||
				DATA.healthRecord?.vaccinationCovidInfluenza === null ||
				DATA.healthRecord?.vaccinationCovidInfluenza === undefined
			) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "healthIssuesError",
						error: "You must provide an answer to each of the above health declaration questions.",
					})
				);
			}
		}
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.healthDeclarationSignature)) {
			if (!DATA.healthRecord?.signature) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "healthSignatureError",
						error: "Signature is required.",
					})
				);
			}
		}
	} else if (STEP === "bankDetails") {
		if (!DATA.bankDetails) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "jobOp3Error",
					error: "Either yes or no must be selected.",
				})
			);
		} else {
			if (DATA.bankDetails.jobOp1 === null || DATA.bankDetails.jobOp1 === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "jobOp1Error",
						error: "You need to select only one of the statements.",
					})
				);
			}
			if (DATA.bankDetails.jobOp2 === null || DATA.bankDetails.jobOp2 === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "jobOp2Error",
						error: "You need to select only one of the statements.",
					})
				);
			}
			if (DATA.bankDetails.jobOp3 === null || DATA.bankDetails.jobOp3 === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "jobOp3Error",
						error: "You need to select only one of the statements.",
					})
				);
			}
			if (
				DATA?.bankDetails?.bankAccountName?.trim() ||
				DATA?.bankDetails?.bankName?.trim() ||
				DATA?.bankDetails?.accountNumber?.trim() ||
				DATA?.bankDetails?.sortCode?.trim() ||
				DATA?.bankDetails?.niNumber?.trim()
			) {
				if (!DATA?.bankDetails?.bankAccountName?.trim()) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "bankAccountNameError",
							error: "Bank account name is required.",
						})
					);
				}
				if (!DATA?.bankDetails?.bankName?.trim()) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "bankNameError",
							error: "Bank name is required.",
						})
					);
				}
				if (!DATA?.bankDetails?.accountNumber?.trim()) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "accountNumberError",
							error: "Account number is required.",
						})
					);
				}
				if (!DATA?.bankDetails?.sortCode?.trim()) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "sortCodeError",
							error: "Account sort code is required.",
						})
					);
				}
				if (!DATA?.bankDetails?.niNumber?.trim()) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "niNumberError",
							error: "National Insurance Number is required.",
						})
					);
				}
			}
			if (formQuestions?.find((question) => question.type === FormQuestionModuleType.candidate && question.question === FormQuestionType.studentLoan)) {
				if (!DATA?.bankDetails?.studentLoan || DATA?.bankDetails?.studentLoan?.studentLoan === null || DATA?.bankDetails?.studentLoan?.studentLoan === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "studentLoanError",
							error: "Either yes or no must be selected.",
						})
					);
				}
			}
		}

		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingBankRequired)) {
			if (!DATA?.bankDetails?.bankAccountName?.trim()) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "bankAccountNameError",
						error: "Bank account name is required.",
					})
				);
			}
			if (!DATA?.bankDetails?.bankName?.trim()) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "bankNameError",
						error: "Bank name is required.",
					})
				);
			}
			if (!DATA?.bankDetails?.accountNumber?.trim()) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "accountNumberError",
						error: "Account number is required.",
					})
				);
			}
			if (!DATA?.bankDetails?.sortCode?.trim()) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "sortCodeError",
						error: "Account sort code is required.",
					})
				);
			}
			if (!DATA?.bankDetails?.niNumber?.trim()) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "niNumberError",
						error: "National Insurance Number is required.",
					})
				);
			}
		}
	} else if (STEP === "documents") {
		if (!DATA.documents?.length) {
			const requiredItems = RightToWorkAvailableItems?.filter?.((item) => item.required) || [];

			const passportGroup = [RightToWork.UKPassport, RightToWork.ForeignPassport, RightToWork.EuropeanPassport, RightToWork.BirthCertificate];

			if (requiredItems?.length) {
				// Group the required items
				const passportDocs = requiredItems.filter((item) => passportGroup.includes(item.ID));
				const otherDocs = requiredItems.filter((item) => !passportGroup.includes(item.ID));

				// Build error message
				const errorParts = [];

				if (passportDocs.length) {
					errorParts.push(`One of: ${passportDocs.map((item) => item.Name).join(", ")}`);
				}

				if (otherDocs.length) {
					errorParts.push(otherDocs.map((item) => item.Name).join(", "));
				}

				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "documentsError",
						error: `The following documents are required: ${errorParts.join("; ")}.`,
					})
				);
			}
		} else {
			const requiredItems = RightToWorkAvailableItems?.filter?.((item) => item.required) || [];
			const docTypes = DATA?.documents?.filter?.((doc) => doc.docType)?.map?.((doc) => parseInt(doc.docType)) || [];

			const passportGroup = [RightToWork.UKPassport, RightToWork.ForeignPassport, RightToWork.EuropeanPassport, RightToWork.BirthCertificate];

			// Check if any uploaded document is in passport group
			const hasPassportDoc = docTypes.some((docType) => passportGroup.includes(docType));

			// Filter out passport group docs from required items if one is already uploaded
			const missingDocs = requiredItems.filter((item) => {
				if (hasPassportDoc && passportGroup.includes(item.ID)) {
					return false; // Skip requiring other passport docs
				}
				return !docTypes.includes(item.ID);
			});

			if (missingDocs.length) {
				// Group the missing docs
				const passportDocs = missingDocs.filter((item) => passportGroup.includes(item.ID));
				const otherDocs = missingDocs.filter((item) => !passportGroup.includes(item.ID));

				// Build error message
				const errorParts = [];

				if (passportDocs.length) {
					errorParts.push(`One of: ${passportDocs.map((item) => item.Name).join(", ")}`);
				}

				if (otherDocs.length) {
					errorParts.push(otherDocs.map((item) => item.Name).join(", "));
				}

				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "documentsError",
						error: `The following documents are required: ${errorParts.join("; ")}.`,
					})
				);
			}
		}
	} else if (STEP === "profilePhoto") {
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.onboardingProfilePhotoRequired)) {
			if (!DATA.profilePhoto) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "profilePhotoError",
						error: "Profile photo is required.",
					})
				);
			}
		}
	} else if (STEP === "declaration") {
		if (!DATA.declarationCheckConsentName) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "declarationCheckConsentNameError",
					error: "Signature is required.",
				})
			);
		}
	} else if (STEP === "contractForService") {
		if (!DATA.contractForServiceName) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "contractForServiceNameError",
					error: "Signature is required.",
				})
			);
		}
	}
	return { valid, errors };
};

export const ValidateCandidateOnboardingBankDetails = (DATA, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA?.bankDetails?.bankAccountName?.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "bankAccountNameError",
				error: "Bank account name is required.",
			})
		);
	}
	if (!DATA?.bankDetails?.bankName?.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "bankNameError",
				error: "Bank name is required.",
			})
		);
	}
	if (!DATA?.bankDetails?.accountNumber?.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "accountNumberError",
				error: "Account number is required.",
			})
		);
	}
	if (!DATA?.bankDetails?.sortCode?.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "sortCodeError",
				error: "Account sort code is required.",
			})
		);
	}
	if (!DATA?.bankDetails?.niNumber?.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "niNumberError",
				error: "National Insurance Number is required.",
			})
		);
	}
	if (DATA?.bankDetails?.jobOp1 === null || DATA?.bankDetails?.jobOp1 === undefined) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "jobOp1Error",
				error: "Either yes or no must be selected.",
			})
		);
	}
	if (DATA?.bankDetails?.jobOp2 === null || DATA?.bankDetails?.jobOp2 === undefined) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "jobOp2Error",
				error: "Either yes or no must be selected.",
			})
		);
	}
	if (DATA?.bankDetails?.jobOp3 === null || DATA?.bankDetails?.jobOp3 === undefined) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "jobOp3Error",
				error: "Either yes or no must be selected.",
			})
		);
	}
	if (formQuestions?.find((question) => question.type === FormQuestionModuleType.candidate && question.question === FormQuestionType.studentLoan)) {
		if (!DATA?.bankDetails?.studentLoan || DATA?.bankDetails?.studentLoan?.studentLoan === null || DATA?.bankDetails?.studentLoan?.studentLoan === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "studentLoanError",
					error: "Either yes or no must be selected.",
				})
			);
		}
	}
	return { valid, errors };
};

export const ValidateReferenceSubmissionCreate = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Candidate name is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateReferenceSubmissionStep = (DATA, STEP, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (STEP === 2) {
		if (!DATA.name) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "nameError",
					error: "Candidate name is required.",
				})
			);
		}
		if (DATA.referenceType === 1 || DATA.referenceType === 2 || DATA.referenceType === 4) {
			if (!DATA.dateFrom) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "dateFromError",
						error: "Date started is required.",
					})
				);
			}
			if (!DATA.dateTo) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "dateToError",
						error: "Date left is required.",
					})
				);
			}
			if (!DATA.position) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "positionError",
						error: "Position held is required.",
					})
				);
			}
			if (!DATA.relationship) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "relationshipError",
						error: "Relationship to candidate is required.",
					})
				);
			}
		}

		if (DATA.referenceType === 6) {
			if (!DATA.dateFrom) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "dateFromError",
						error: "Date started is required.",
					})
				);
			}
			if (!DATA.dateTo) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "dateToError",
						error: "Date left is required.",
					})
				);
			}
			if (!DATA.position) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "positionError",
						error: "Position(s) held is required.",
					})
				);
			}

			if (!DATA.applicantVolunteerRole) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "applicantVolunteerRoleError",
						error: "Applicant volunteer role is required.",
					})
				);
			}

			if (!DATA.natureOfVolunteerRole) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "natureOfVolunteerRoleError",
						error: "Nature of role is required.",
					})
				);
			}
		}

		if (DATA.referenceType === 3) {
			if (!DATA.durationKnown) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "durationKnownError",
						error: "Duration known is required.",
					})
				);
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.alternativeCharacterReferenceProForma)) {
				if (!DATA.capacityKnown) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "capacityKnownError",
							error: "Capacity known is required.",
						})
					);
				}
				if (!DATA.honestAndReliable) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "honestAndReliableError",
							error: "Honest and reliable field is required.",
						})
					);
				}
				if (!DATA.wouldYouRecommend) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "wouldYouRecommendError",
							error: "Would you recommend field is required.",
						})
					);
				}
				if (!DATA.relationshipToApplicant) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "relationshipToApplicantError",
							error: "Relationship to applicant is required.",
						})
					);
				}
			}
		}
		if (DATA.referenceType === 2) {
			if (!DATA.subjectAreas) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectAreasError",
						error: "Key responsibilities/duties required.",
					})
				);
			}
		}
		if (DATA.referenceType === 1 || DATA.referenceType === 2 || DATA.referenceType === 6) {
			if (DATA.reEmploy === null || DATA.reEmploy === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "reEmployError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.reEmploy !== null && DATA.reEmploy !== undefined && !DATA.reEmploy && !DATA.reEmplyDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "reEmployDescriptionError",
						error: "Further details are required.",
					})
				);
			}
		}

		if (DATA.referenceType === 1 && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
			if (DATA.reEmploy !== null && DATA.reEmploy !== undefined && DATA.reEmploy === true && !(DATA.dayToDay || DATA.longTerm || DATA.permanent)) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "reEmployError",
						error: "At least one type must be selected.",
					})
				);
			}
		}

		if (DATA.referenceType === 4) {
			if (!DATA.daysWorked) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "daysWorkedError",
						error: "Approximate no. days worked is required.",
					})
				);
			}
			if (!DATA.reasonsForLeaving) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "reasonsForLeavingError",
						error: "A reason for leaving is required.",
					})
				);
			}
		}
		if (DATA.referenceType == ReferenceTypes.Academic) {
			if (!DATA.dateFrom) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "dateFromError",
						error: "Studieid from is required.",
					})
				);
			}
			if (!DATA.dateTo) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "dateToError",
						error: "Studied to is required.",
					})
				);
			}
			if (!DATA.relationship) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "relationshipError",
						error: "Relationship to candidate is required.",
					})
				);
			}
			if (!DATA.subjectAreas) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectAreasError",
						error: "Subjects studied is required.",
					})
				);
			}
		}
		if (DATA.referenceType === 1 && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
			if (!DATA.finalSalary) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "finalSalaryError",
						error: "Final salary is required.",
					})
				);
			}
			if (!DATA.subjectAreas) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectAreasError",
						error: "Further details are required.",
					})
				);
			}
			if (!DATA.subjectAreas) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "reasonsForLeavingError",
						error: "Further details are required.",
					})
				);
			}
		}
		if (DATA.referenceType === 2 && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
			if (!DATA.finalSalary) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "finalSalaryError",
						error: "Final salary is required.",
					})
				);
			}
		}
	} else if (STEP === 3) {
		if (
			DATA.referenceType === 1 ||
			(DATA.referenceType === 2 && !ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) ||
			DATA.referenceType === 4
		) {
			if (DATA.disciplinaryUndergoing === null || DATA.disciplinaryUndergoing === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "disciplinaryUndergoingError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.disciplinaryUndergoing !== null && DATA.disciplinaryUndergoing !== undefined && DATA.disciplinaryUndergoing && !DATA.disciplinaryUndergoingDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "disciplinaryUndergoingDescriptionError",
						error: "Further details are required.",
					})
				);
			}
		}
		if (
			DATA.referenceType === 1 ||
			(DATA.referenceType === 2 && !ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) ||
			DATA.referenceType === 3 ||
			DATA.referenceType === 4 ||
			DATA.referenceType === 5
		) {
			if (DATA.disciplinaryHistory === null || DATA.disciplinaryHistory === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "disciplinaryHistoryError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.disciplinaryHistory !== null && DATA.disciplinaryHistory !== undefined && DATA.disciplinaryHistory && !DATA.disciplinaryHistoryDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "disciplinaryHistoryDescriptionError",
						error: "Further details are required.",
					})
				);
			}
			if (DATA.safeguardingConcerns === null || DATA.safeguardingConcerns === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "safeguardingConcernsError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.safeguardingConcerns !== null && DATA.safeguardingConcerns !== undefined && DATA.safeguardingConcerns && !DATA.safeguardingConcernsDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "safeguardingConcernsDescriptionError",
						error: "Further details are required.",
					})
				);
			}
			if (DATA.rejectionReason === null || DATA.rejectionReason === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "rejectionReasonError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.rejectionReason !== null && DATA.rejectionReason !== undefined && DATA.rejectionReason && !DATA.rejectionReasonDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "rejectionReasonDescriptionError",
						error: "Further details are required.",
					})
				);
			}
		}

		if (DATA.referenceType === 2 && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.showMedicalReferenceQuestions)) {
			if (DATA.sicknessRecord === null || DATA.sicknessRecord === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "sicknessRecordError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.sicknessRecord !== null && DATA.sicknessRecord !== undefined && DATA.sicknessRecord && !DATA.sicknessRecordDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "sicknessRecordDescriptionError",
						error: "Further details are required.",
					})
				);
			}
		}
		if ((DATA.referenceType === 2 || DATA.referenceType === 6) && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
			if (!DATA.reasonsForLeaving) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "reasonsForLeavingError",
						error: "Further details are required.",
					})
				);
			}
			if (DATA.honestAndTrustworthy === null || DATA.honestAndTrustworthy === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "honestAndTrustworthyError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.honestAndTrustworthy !== null && DATA.honestAndTrustworthy !== undefined && !DATA.honestAndTrustworthy && !DATA.honestAndTrustworthyDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "honestAndTrustworthyDescriptionError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.subjectOfDisciplinaryProcedure === null || DATA.subjectOfDisciplinaryProcedure === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectOfDisciplinaryProcedureError",
						error: "An answer is required.",
					})
				);
			}
			if (
				DATA.subjectOfDisciplinaryProcedure !== null &&
				DATA.subjectOfDisciplinaryProcedure !== undefined &&
				DATA.subjectOfDisciplinaryProcedure &&
				!DATA.subjectOfDisciplinaryProcedureDescription
			) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectOfDisciplinaryProcedureDescriptionError",
						error: "An answer is required.",
					})
				);
			}
		}
		if (DATA.referenceType === 6) {
			if (DATA.subjectOfDisciplinaryAction === null || DATA.subjectOfDisciplinaryAction === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectOfDisciplinaryActionError",
						error: "An answer is required.",
					})
				);
			}
			if (
				DATA.subjectOfDisciplinaryAction !== null &&
				DATA.subjectOfDisciplinaryAction !== undefined &&
				DATA.subjectOfDisciplinaryAction === true &&
				!DATA.subjectOfDisciplinaryActionDescription
			) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "subjectOfDisciplinaryActionDescriptionError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.safeguardingChildProcedure === null || DATA.safeguardingChildProcedure === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "safeguardingChildProcedureError",
						error: "An answer is required.",
					})
				);
			}
			if (DATA.safeguardingChildProcedure !== null && DATA.safeguardingChildProcedure !== undefined && DATA.safeguardingChildProcedure === true && !DATA.safeguardingChildProcedureDescription) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "safeguardingChildProcedureDescriptionError",
						error: "An answer is required.",
					})
				);
			}
		}
	} else if (STEP === 4) {
		if (DATA.referenceType === 1) {
			if (DATA.manageClassBehaviour === null || DATA.manageClassBehaviour === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "manageClassBehaviourError",
						error: "A grade is required.",
					})
				);
			}
			if (!ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
				if (DATA.profressionalism === null || DATA.profressionalism === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "profressionalismError",
							error: "A grade is required.",
						})
					);
				}
			}
			if (DATA.planning === null || DATA.planning === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "planningError",
						error: "A grade is required.",
					})
				);
			}
			if (DATA.ability === null || DATA.ability === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "abilityError",
						error: "A grade is required.",
					})
				);
			}
			if (DATA.rapport === null || DATA.rapport === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "rapportError",
						error: "A grade is required.",
					})
				);
			}
			if (DATA.agencySuitability === null || DATA.agencySuitability === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "agencySuitabilityError",
						error: "A grade is required.",
					})
				);
			}
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.educationReferenceExtended)) {
				if (DATA.subjectKnowledge === null || DATA.subjectKnowledge === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "subjectKnowledgeError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.peerRelationship === null || DATA.peerRelationship === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "peerRelationshipError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.encourageHighStandards === null || DATA.encourageHighStandards === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "encourageHighStandardsError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.toleranceFrustration === null || DATA.toleranceFrustration === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "toleranceFrustrationError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.organisationOrder === null || DATA.organisationOrder === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "organisationOrderError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.afterschoolActivities === null || DATA.afterschoolActivities === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "afterschoolActivitiesError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.workEthic === null || DATA.workEthic === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "workEthicError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.adaptability === null || DATA.adaptability === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "adaptabilityError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.teamPlayer === null || DATA.teamPlayer === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "teamPlayerError",
							error: "A grade is required.",
						})
					);
				}
				if (DATA.overallRating === null || DATA.overallRating === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "overallRatingError",
							error: "A grade is required.",
						})
					);
				}
			}
			if (DATA.attendance === null || DATA.attendance === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "attendanceError",
						error: "A grade is required.",
					})
				);
			}
		}
		if (DATA.referenceType === 2) {
			if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.showMedicalReferenceQuestions)) {
				if (DATA.willingToLearn === null || DATA.willingToLearn === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "willingToLearnError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.contributionToDepartment === null || DATA.contributionToDepartment === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "contributionToDepartmentError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.reliability === null || DATA.reliability === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "reliabilityError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.selfMotivation === null || DATA.selfMotivation === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "selfMotivationError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.copeUnderPressure === null || DATA.copeUnderPressure === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "copeUnderPressureError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.honestyIntegrity === null || DATA.honestyIntegrity === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "honestyIntegrityError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.communicationTeamwork === null || DATA.communicationTeamwork === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "communicationTeamworkError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.clinicalSkills === null || DATA.clinicalSkills === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "clinicalSkillsError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.clinicalKnowledge === null || DATA.clinicalKnowledge === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "clinicalKnowledgeError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.organisationalSkills === null || DATA.organisationalSkills === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "organisationalSkillsError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.managementSkills === null || DATA.managementSkills === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "managementSkillsError",
							error: "A grade is required required.",
						})
					);
				}
			}
			if (!ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
				if (DATA.profressionalism === null || DATA.profressionalism === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "profressionalismError",
							error: "A grade is required required.",
						})
					);
				}
				if (DATA.relationshipColleagues === null || DATA.relationshipColleagues === undefined) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "relationshipColleaguesError",
							error: "A grade is required required.",
						})
					);
				}
			}
			if (DATA.trustworthy === null || DATA.trustworthy === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "trustworthyError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.punctuality === null || DATA.punctuality === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "punctualityError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.workWithChildrenSuitability === null || DATA.workWithChildrenSuitability === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "workWithChildrenSuitabilityError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.attendance === null || DATA.attendance === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "attendanceError",
						error: "A grade is required.",
					})
				);
			}
		}
		if (DATA.referenceType === 3 || DATA.referenceType == ReferenceTypes.Academic) {
			if (DATA.punctuality === null || DATA.punctuality === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "punctualityError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.reliability === null || DATA.reliability === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "reliabilityError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.profressionalism === null || DATA.profressionalism === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "profressionalismError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.trustworthy === null || DATA.trustworthy === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "trustworthyError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.willingnessToLearn === null || DATA.willingnessToLearn === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "willingnessToLearnError",
						error: "A grade is required required.",
					})
				);
			}
			if (DATA.workWithChildrenSuitability === null || DATA.workWithChildrenSuitability === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "workWithChildrenSuitabilityError",
						error: "A grade is required required.",
					})
				);
			}
		}
		if (DATA.referenceType === ReferenceTypes.Volunteer && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
			if (DATA.generalConduct === null || DATA.generalConduct === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "generalConductError",
						error: "A grade is required.",
					})
				);
			}

			if (DATA.workPerformance === null || DATA.workPerformance === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "workPerformanceError",
						error: "A grade is required.",
					})
				);
			}

			if (DATA.attitudeToWork === null || DATA.attitudeToWork === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "attitudeToWorkError",
						error: "A grade is required.",
					})
				);
			}

			if (DATA.initiative === null || DATA.initiative === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "initiativeError",
						error: "A grade is required.",
					})
				);
			}

			if (DATA.timeKeeping === null || DATA.timeKeeping === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "timeKeepingError",
						error: "A grade is required.",
					})
				);
			}

			if (DATA.relationshipColleagues === null || DATA.relationshipColleagues === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "relationshipColleaguesError",
						error: "A grade is required.",
					})
				);
			}

			if (DATA.relationshipCustomers === null || DATA.relationshipCustomers === undefined) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "relationshipCustomersError",
						error: "A grade is required.",
					})
				);
			}
		}
	}
	return { valid, errors };
};

export const ValidateReferenceSubmission = (DATA, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Candidate name is required.",
			})
		);
	}
	if (DATA.referenceType === 1 || DATA.referenceType === 2) {
		if (!DATA.dateFrom) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "dateFromError",
					error: "Date started is required.",
				})
			);
		}
		if (!DATA.dateTo) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "dateToError",
					error: "Date left is required.",
				})
			);
		}
		if (!DATA.position) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "positionError",
					error: "Position held is required.",
				})
			);
		}
		if (!DATA.relationship) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "relationshipError",
					error: "Relationship to candidate is required.",
				})
			);
		}
	}
	if (DATA.referenceType === 3) {
		if (!DATA.durationKnown) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "durationKnownError",
					error: "Duration known is required.",
				})
			);
		}
		if (ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.alternativeCharacterReferenceProForma)) {
			if (!DATA.capacityKnown) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "capacityKnownError",
						error: "Capacity known is required.",
					})
				);
			}
			if (!DATA.honestAndReliable) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "honestAndReliableError",
						error: "Honest and reliable field is required.",
					})
				);
			}
			if (!DATA.wouldYouRecommend) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "wouldYouRecommendError",
						error: "Would you recommend field is required.",
					})
				);
			}
		}
	}

	if (DATA.referenceType === 2) {
		if (!DATA.subjectAreas) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "subjectAreas",
					error: "Key responsibilities/duties is required.",
				})
			);
		}
	}
	if (DATA.referenceType === 1 || DATA.referenceType === 2) {
		if (DATA.reEmploy === null || DATA.reEmploy === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "reEmployError",
					error: "An answer is required.",
				})
			);
		}
		if (DATA.reEmploy !== null && DATA.reEmploy !== undefined && !DATA.reEmploy && !DATA.reEmplyDescription) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "reEmployDescriptionError",
					error: "Further details are required.",
				})
			);
		}
		if (DATA.disciplinaryUndergoing === null || DATA.disciplinaryUndergoing === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "disciplinaryUndergoingError",
					error: "An answer is required.",
				})
			);
		}
		if (DATA.disciplinaryUndergoing !== null && DATA.disciplinaryUndergoing !== undefined && DATA.disciplinaryUndergoing && !DATA.disciplinaryUndergoingDescription) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "disciplinaryUndergoingDescriptionError",
					error: "Further details are required.",
				})
			);
		}
	}

	if (DATA.referenceType === 1 && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
		if (DATA.reEmploy !== null && DATA.reEmploy !== undefined && DATA.reEmploy === true && !(DATA.dayToDay || DATA.longTerm || DATA.permanent)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "reEmployError",
					error: "At least one type must be selected.",
				})
			);
		}
	}

	if (DATA.disciplinaryHistory === null || DATA.disciplinaryHistory === undefined) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "disciplinaryHistoryError",
				error: "An answer is required.",
			})
		);
	}
	if (DATA.disciplinaryHistory !== null && DATA.disciplinaryHistory !== undefined && DATA.disciplinaryHistory && !DATA.disciplinaryHistoryDescription) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "disciplinaryHistoryDescriptionError",
				error: "Further details are required.",
			})
		);
	}
	if (DATA.rejectionReason === null || DATA.rejectionReason === undefined) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "rejectionReasonError",
				error: "An answer is required.",
			})
		);
	}
	if (DATA.rejectionReason !== null && DATA.rejectionReason !== undefined && DATA.rejectionReason && !DATA.rejectionReasonDescription) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "rejectionReasonDescriptionError",
				error: "Further details are required.",
			})
		);
	}

	if (DATA.referenceType === 1) {
		if (DATA.manageClassBehaviour === null || DATA.manageClassBehaviour === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "manageClassBehaviourError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.profressionalism === null || DATA.profressionalism === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "profressionalismError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.planning === null || DATA.planning === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "planningError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.ability === null || DATA.ability === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "abilityError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.rapport === null || DATA.rapport === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "rapportError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.agencySuitability === null || DATA.agencySuitability === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "agencySuitabilityError",
					error: "A grade is required required.",
				})
			);
		}
	}
	if (DATA.referenceType === 2) {
		if (DATA.profressionalism === null || DATA.profressionalism === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "profressionalismError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.relationshipColleagues === null || DATA.relationshipColleagues === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "relationshipColleaguesError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.trustworthy === null || DATA.trustworthy === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "trustworthyError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.punctuality === null || DATA.punctuality === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "punctualityError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.workWithChildrenSuitability === null || DATA.workWithChildrenSuitability === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "workWithChildrenSuitabilityError",
					error: "A grade is required required.",
				})
			);
		}
	}
	if (DATA.referenceType === 3) {
		if (DATA.punctuality === null || DATA.punctuality === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "punctualityError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.reliability === null || DATA.reliability === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "reliabilityError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.profressionalism === null || DATA.profressionalism === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "profressionalismError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.trustworthy === null || DATA.trustworthy === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "trustworthyError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.willingnessToLearn === null || DATA.willingnessToLearn === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "willingnessToLearnError",
					error: "A grade is required required.",
				})
			);
		}
		if (DATA.workWithChildrenSuitability === null || DATA.workWithChildrenSuitability === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "workWithChildrenSuitabilityError",
					error: "A grade is required required.",
				})
			);
		}
	}

	if (DATA.referenceType === ReferenceTypes.Volunteer && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.vidaReferenceQuestions)) {
		if (DATA.generalConduct === null || DATA.generalConduct === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "generalConductError",
					error: "A grade is required.",
				})
			);
		}

		if (DATA.workPerformance === null || DATA.workPerformance === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "workPerformanceError",
					error: "A grade is required.",
				})
			);
		}

		if (DATA.attitudeToWork === null || DATA.attitudeToWork === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "attitudeToWorkError",
					error: "A grade is required.",
				})
			);
		}

		if (DATA.initiative === null || DATA.initiative === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "initiativeError",
					error: "A grade is required.",
				})
			);
		}

		if (DATA.timeKeeping === null || DATA.timeKeeping === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "timeKeepingError",
					error: "A grade is required.",
				})
			);
		}

		if (DATA.relationshipColleagues === null || DATA.relationshipColleagues === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "relationshipColleaguesError",
					error: "A grade is required.",
				})
			);
		}

		if (DATA.relationshipCustomers === null || DATA.relationshipCustomers === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "relationshipCustomersError",
					error: "A grade is required.",
				})
			);
		}
	}

	if (!DATA.reffereeName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "reffereeNameError",
				error: "Your name is required.",
			})
		);
	}
	if (!DATA.reffereeEmail) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "reffereeEmailError",
				error: "Your email is required.",
			})
		);
	}
	if (DATA.referenceType !== 3) {
		if (!DATA.reffereePosition) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "reffereePositionError",
					error: "Your job role/position is required.",
				})
			);
		}
		if (!DATA.reffereeEmployer) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "reffereeEmployerError",
					error: "Your employer name is required.",
				})
			);
		}
	}
	if (!formQuestions?.find((question) => question.type === FormQuestionModuleType.candidate && question.question === FormQuestionType.noReferenceConsent)) {
		if (DATA.gdprShare === null || DATA.gdprShare === undefined) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "gdprShareError",
					error: "Consent to share response is required.",
				})
			);
		}
	}
	return { valid, errors };
};

export const ValidateBooking = (DATA, formQuestions?: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA.type) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "typeError",
				error: "Booking type is required.",
			})
		);
	}
	if (DATA.type == BookingType.Tutor && !DATA.subType) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "subTypeError",
				error: "Tutor type is required.",
			})
		);
	}
	if (!DATA.draft && !DATA.candidate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "candidateError",
				error: "Candidate is required.",
			})
		);
	}
	if (!DATA.client) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientError",
				error: "Client is required.",
			})
		);
	}
	if (DATA.client && !DATA.clientContact) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientContactError",
				error: "Client contact is required.",
			})
		);
	}
	if (DATA.client && DATA.clientRecord && !DATA.clientRecord?.contacts.find((contact) => contact._id === DATA.clientContact)?.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientContactError",
				error: "Client contact is required.",
			})
		);
	}
	if (DATA.client && !DATA.clientAddress?.addressLine1) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientAddressLine1Error",
				error: "Client address line 1 is required.",
			})
		);
	}
	if (DATA.client && !DATA.clientAddress?.postcode) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientAddressPostcodeError",
				error: "Client postcode is required.",
			})
		);
	}
	if (!DATA.draft && DATA.type && DATA.type != BookingType.Interview && ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.healthSafetyBookingRequired)) {
		if (!DATA.healthSafetyCheck) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "healthSafetyCheckError",
					error: "Health and safety confirmation is required.",
				})
			);
		}

		if (DATA.healthSafetyCheck && !DATA.healthSafetyCheckContact) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "healthSafetyCheckContactError",
					error: "Health and safety contact is required.",
				})
			);
		}
	}
	if (!DATA.role) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "roleError",
				error: "Role is required.",
			})
		);
	}
	// if (DATA.type === BookingType.Standard && !DATA.awr) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "awrError",
	// 			error: "AWR duration is required.",
	// 		})
	// 	);
	// }
	if ((DATA.type === BookingType.Standard || DATA.type === BookingType.Tutor) && !DATA.chargeRate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "chargeRateError",
				error: "Charge rate is required.",
			})
		);
	}
	if ((DATA.type === BookingType.Standard || DATA.type === BookingType.Tutor) && !DATA.payRate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "payRateError",
				error: "Pay rate is required.",
			})
		);
	}
	if (!DATA.draft && (DATA.type === BookingType.Standard || DATA.type === BookingType.Tutor) && !DATA.candidatePayType) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "candidatePayTypeError",
				error: "Candidate pay type (paye/ umbrella) is required.",
			})
		);
	}
	if ((DATA.type === BookingType.Interview || DATA.type === BookingType.Trial) && DATA.salary && !DATA.salaryPayType) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "salaryPayTypeError",
				error: "Salary pay type (annual/ daily) is required.",
			})
		);
	}
	if (!DATA.days || !DATA.days.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "daysError",
				error: "At least 1 day is required to make a booking.",
			})
		);
	}
	if (DATA.type === BookingType.Perm && DATA.days?.length && DATA.days?.length > 1) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "daysError",
				error: "Only select the start date for permanent placements.",
			})
		);
	}
	return { valid, errors };
};

export const ValidatePlaceholderBooking = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.client) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientError",
				error: "Client is required.",
			})
		);
	}
	if (!DATA.days || !DATA.days.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "daysError",
				error: "At least 1 day is required to make a booking.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateConvertBooking = (DATA) => {
	let valid = true,
		errors = [];
	if ((DATA.type === BookingType.Standard || DATA.type === BookingType.Tutor) && !DATA.chargeRate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "chargeRateError",
				error: "Charge rate is required.",
			})
		);
	}
	if ((DATA.type === BookingType.Standard || DATA.type === BookingType.Tutor) && !DATA.payRate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "payRateError",
				error: "Pay rate is required.",
			})
		);
	}
	if ((DATA.type === BookingType.Standard || DATA.type === BookingType.Tutor) && !DATA.candidatePayType) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "candidatePayTypeError",
				error: "Candidate pay type (paye/ umbrella) is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateTimesheet = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.candidateName || !DATA.candidateName.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "candidateNameError",
				error: "Candidate is required.",
			})
		);
	}
	if (!DATA.candidate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "candidateError",
				error: "Candidate is required.",
			})
		);
	}
	if (!DATA.clientName || !DATA.clientName.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientNameError",
				error: "Client is required.",
			})
		);
	}
	if (!DATA.clientEmail || !DATA.clientEmail.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientEmailError",
				error: "Client email is required.",
			})
		);
	}
	if (DATA.clientEmail && !IsEmail(DATA.clientEmail)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientEmailError",
				error: "Client email is not a valid email address.",
			})
		);
	}
	if (!DATA.client) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "clientError",
				error: "Client is required.",
			})
		);
	}
	if (!DATA.booking) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "bookingError",
				error: "Booking is required.",
			})
		);
	}
	if (!DATA.days || !DATA.days.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "daysError",
				error: "The period of time the timesheet covers is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateTemplate = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Template name is required.",
			})
		);
	}
	if (!DATA.category) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "categoryError",
				error: "Template category is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateSingleSend = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Template name is required.",
			})
		);
	}
	if (!DATA.subject) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "subjectError",
				error: "Email subject is required.",
			})
		);
	}
	if (!DATA.recipients || !DATA.recipients.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "recipientsError",
				error: "At least one recipient is required.",
			})
		);
	}
	if (!DATA.template) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "templateError",
				error: "Email template is required.",
			})
		);
	}
	if (DATA.scheduleDate && !DATA.scheduleTime) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "scheduleTimeError",
				error: "Schedule Date and Time is required.",
			})
		);
	}
	if (DATA.scheduleTime && !IsValidTime(DATA.scheduleTime)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "scheduleTimeError",
				error: "Schedule Date and Time is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateMeAccount = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.firstName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "firstNameError",
				error: "First name is required.",
			})
		);
	}
	if (!DATA.lastName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "lastNameError",
				error: "Last name is required.",
			})
		);
	}
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Email is required.",
			})
		);
	} else {
		if (!IsEmail(DATA.email)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "emailError",
					error: "Please enter a valid email address.",
				})
			);
		}
	}
	if (!DATA.username) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "usernameError",
				error: "Username is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCustomerAccount = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Company name is required.",
			})
		);
	}
	if (DATA.contactEmail && !IsEmail(DATA.contactEmail)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "contactEmailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (DATA.contactTelephone && !IsTelephone(DATA.contactTelephone)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "contactTelephoneError",
				error: "Please enter a valid telephone number.",
			})
		);
	}
	if (!DATA.logo) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "logoError",
				error: "Company logo is required.",
			})
		);
	}
	if (!DATA.address) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "addressError",
				error: "Company address is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateExternalComms = (DATA) => {
	let valid = true,
		errors = [];

	if (DATA.onboardingEmail && !IsEmail(DATA.onboardingEmail)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "onboardingEmailError",
				error: "Please enter a valid email address for onboarding communications.",
			})
		);
	}

	if (DATA.referenceEmail && !IsEmail(DATA.referenceEmail)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "referenceEmailError",
				error: "Please enter a valid email address for reference requests.",
			})
		);
	}

	if (DATA.timesheetEmail && !IsEmail(DATA.timesheetEmail)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "timesheetEmailError",
				error: "Please enter a valid email address for timesheet communications.",
			})
		);
	}

	return { valid, errors };
};

export const ValidateUser = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.firstName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "firstNameError",
				error: "First name is required.",
			})
		);
	}
	if (!DATA.lastName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "lastNameError",
				error: "Last name is required.",
			})
		);
	}
	if (!DATA.username) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "usernameError",
				error: "Username is required.",
			})
		);
	}
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Email is required.",
			})
		);
	}
	if (DATA.email && !IsEmail(DATA.email)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (!DATA.position) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "positionError",
				error: "Job title is required.",
			})
		);
	}
	if (DATA.commissionThreshold === null || DATA.commissionThreshold === undefined) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "commissionThresholdError",
				error: "Commission threshold is required. This value can be 0.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCommission = (DATA: ICommission) => {
	let valid = true,
		errors = [];
	if (!DATA.min) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "minError",
				error: "Commission threshold value is required.",
			})
		);
	}
	if (!DATA.commission) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "commissionError",
				error: "A percentage commission is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateImageUpload = (DATA) => {
	let valid = true,
		errors = [];
	if (!(DATA.type === "image/jpeg" || DATA.type === "image/png" || DATA.type === "image/jpg")) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "imageError",
				error: "The selected file must be a jpeg or png.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateDocumentUploadType = (DATA, validTypes) => {
	let valid = true,
		errors = [];
	if (!validTypes.includes(DATA.type)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "imageError",
				error: "The selected file must be one of the following types: " + validTypes.join(", "),
			})
		);
	}
	return { valid, errors };
};

export const ValidateBookingCosts = (DATA) => {
	let valid = true,
		errors = [];
	if (DATA.oncosts) {
		if (isNaN(DATA.oncosts)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "oncostsError",
					error: "Booking on-costs must be a number.",
				})
			);
		} else {
			if (parseFloat(DATA.oncosts) < 0 || parseFloat(DATA.oncosts) > 100) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "oncostsError",
						error: "Booking on-costs must be between 0 and 100.",
					})
				);
			}
		}
	}
	return { valid, errors };
};

export const ValidateBookingCostsHoliday = (DATA: BookingCostsHolidayInterface) => {
	let valid = true,
		errors = [];
	if (DATA.holidayPay) {
		if (DATA.holidayPayPercentage && isNaN(DATA.holidayPayPercentage)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "holidayPayPercentageError",
					error: "Holiday pay percentage must be a number.",
				})
			);
		} else {
			if (DATA.holidayPayPercentage) {
				if (parseFloat(DATA.holidayPayPercentage.toString()) < 0 || parseFloat(DATA.holidayPayPercentage.toString()) > 100) {
					valid = false;
					errors.push(
						new FieldValidaionModel({
							name: "holidayPayPercentageError",
							error: "Holiday pay percentage must be between 0 and 100.",
						})
					);
				}
			}
		}
	}
	return { valid, errors };
};

export const ValidateBookingCostsRatesAmend = (DATA: IRates) => {
	let valid = true,
		errors = [];
	if (!DATA?.payRate && !DATA?.chargeRate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "payRateError",
				error: "Pay rate and/ or Charge rate % change is required",
			})
		);
		errors.push(
			new FieldValidaionModel({
				name: "chargeRateError",
				error: "Chare rate and/ or Pay rate % change is required",
			})
		);
	}
	return { valid, errors };
};

export const ValidateContractForService = (DATA: IContractForService) => {
	let valid = true,
		errors = [];
	if (!DATA.contractForService) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "contractForServiceError",
				error: "A valid contract for service PDF must be uploaded.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateTermsOfBusiness = (DATA: ITermsOfBusiness) => {
	let valid = true,
		errors = [];
	if (!DATA.termsOfBusiness) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "termsOfBusinessError",
				error: "A valid terms of business PDF must be uploaded.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateBulkTimesheetCreation = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.bookings || !DATA.bookings.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "oncostsError",
				error: "Booking on-costs is required.",
			})
		);
	}
	if (DATA.bookings.length) {
		const missingClientEmails = DATA.bookings
			.filter((booking) => !booking.clientRecord?.contacts.find((contact) => contact._id === booking.clientContact)?.email)
			.map(
				(booking) =>
					new FieldValidaionModel({
						name: "bookingsError",
						error: `${booking.candidateName}: Client contact is missing from the booking.`,
					})
			);
		if (missingClientEmails.length) {
			valid = false;
			errors = [...errors, ...missingClientEmails];
		}

		const missingCandidateEmails = DATA.bookings
			.filter((booking) => !booking.candidate)
			.map(
				(booking) =>
					new FieldValidaionModel({
						name: "bookingsError",
						error: `${booking.candidateName}: Candidate email is missing from the booking.`,
					})
			);
		if (missingCandidateEmails.length) {
			valid = false;
			errors = [...errors, ...missingCandidateEmails];
		}
	}
	return { valid, errors };
};

export const ValidateXeroContact = (DATA: IXeroClientContact) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Client name is required.",
			})
		);
	}
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (DATA.email && !IsEmail(DATA.email)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Please enter a valid email address.",
			})
		);
	}
	if (!DATA.firstName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "firstNameError",
				error: "First Name is required.",
			})
		);
	}
	if (!DATA.lastName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "lastNameError",
				error: "Last Name.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateXeroInvoice = (DATA: IInvoice & { accountCode: string; invoices: IInvoice[] }) => {
	let valid = true,
		errors = [];
	// if (!DATA.contact) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "contactError",
	// 			error: "Client contact is required.",
	// 		})
	// 	);
	// }
	if (!DATA.date) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "dateError",
				error: "Invoice date is required.",
			})
		);
	}
	if (!DATA.dueDate) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "dueDateError",
				error: "Due date is required.",
			})
		);
	}
	// if (!DATA.invoiceNumber) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "invoiceNumberError",
	// 			error: "Invoice number is required.",
	// 		})
	// 	);
	// }
	// if (!DATA.lineItems || !DATA.lineItems.length) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "lineItemsError",
	// 			error: "At least 1 invoice line item is required.",
	// 		})
	// 	);
	// }
	if (!DATA.invoices || !DATA.invoices.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "invoicesError",
				error: "At least 1 invoice record is required.",
			})
		);
	}
	if (DATA.invoices?.length) {
		if (DATA.invoices.filter((invoice) => !invoice.lineItems?.length).length) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "invoicesError",
					error: "At least 1 invoice line item is required.",
				})
			);
		}
		if (DATA.invoices.filter((invoice) => !invoice.contact).length) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "invoicesError",
					error: "Invoice contacts are required for all selected invoices. Be sure to select or create contacts for the invoices below. Once the contacts have been created for a client you will be able to select them for future invoicies.",
				})
			);
		}
	}
	if (!DATA.accountCode) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "accountCodeError",
				error: "A valid account must be selected.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateXeroLineItem = (DATA: ILineItem & { duration?: string }) => {
	let valid = true,
		errors = [];
	if (!DATA.unitAmount) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "unitAmountError",
				error: "Line item amount is required.",
			})
		);
	}
	if (!DATA.duration) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "descriptionError",
				error: "Line item description is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidatePaymentRun = (DATA) => {
	let valid = true,
		errors = [];
	if (!DATA.timesheets || !DATA.timesheets.length) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "timesheetsError",
				error: "At least one record is required to create a payment run.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCustomId = (DATA: { _customId: string }) => {
	let valid = true,
		errors = [];
	if (!DATA._customId) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "customIdError",
				error: "Custom ID is required.",
			})
		);
	}
	if (DATA._customId && DATA._customId.trim().length < 2) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "customIdError",
				error: "Custom ID must contain atleast 2 characters.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateTuitionReport = (DATA: ITuitionReport, subType: BookingSubType, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA.studentName) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "studentNameError",
				error: "Students name is required",
			})
		);
	}
	if (!subType || subType == BookingSubType.TutorStandard) {
		if (DATA.hoursFromStart === null || DATA.hoursFromStart === undefined || (DATA.hoursFromStart as unknown as string) === "") {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "hoursFromStartError",
					error: "Hours from start is required",
				})
			);
		}
		if (!ShowFormQuestion(formQuestions, FormQuestionModuleType.candidate, FormQuestionType.tuitionNoHoursRemaining)) {
			if (DATA.hoursRemaining === null || DATA.hoursRemaining === undefined || (DATA.hoursRemaining as unknown as string) === "") {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "hoursRemainingError",
						error: "Hours remaining is required",
					})
				);
			}
		}
	}
	if (subType == BookingSubType.TutorMentor || subType == BookingSubType.TutorBasic) {
		if (!DATA.totalHours) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "totalHoursError",
					error: "Total hours worked is required",
				})
			);
		}
	}
	if (!subType || subType == BookingSubType.TutorStandard) {
		if (!DATA.subjectsTaught) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "subjectsTaughtError",
					error: "Subjects taught is required",
				})
			);
		}
		if (!DATA.subjectsAbility) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "subjectsAbilityError",
					error: "Ability/levels working at is required",
				})
			);
		}
		if (!DATA.tuitionObjectives) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "tuitionObjectivesError",
					error: "Tuition objectives is required",
				})
			);
		}
	}
	if (subType == BookingSubType.TutorMentor) {
		if (!DATA.focus) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "focusError",
					error: "Focus of meeting required",
				})
			);
		}
		if (!DATA.otherTopics) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "otherTopicsError",
					error: "Other topics covered in meeting required",
				})
			);
		}
	}
	if (!subType || subType == BookingSubType.TutorStandard) {
		if (!DATA.studentCompletedHomework) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "studentCompletedHomeworkError",
					error: "Reponse required",
				})
			);
		}
		if (!DATA.studentCommunicatedClearly) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "studentCommunicatedClearlyError",
					error: "Reponse required",
				})
			);
		}
		if (!DATA.studentExcellentAttitude) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "studentExcellentAttitudeError",
					error: "Reponse required",
				})
			);
		}
		if (!DATA.studentAskedQuestions) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "studentAskedQuestionsError",
					error: "Reponse required",
				})
			);
		}
		if (!DATA.studentMetObjectives) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "studentMetObjectivesError",
					error: "Reponse required",
				})
			);
		}
		if (!DATA.studentEngaged) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "studentEngagedError",
					error: "Reponse required",
				})
			);
		}
	}
	if (subType == BookingSubType.TutorMentor) {
		if (!DATA.overallSatisfaction) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "overallSatisfactionError",
					error: "Overall satisfaction with meeting required",
				})
			);
		}
	}
	if (DATA.safeguardingConcerns === null || DATA.safeguardingConcerns === undefined) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "safeguardingConcernsError",
				error: "Reponse required",
			})
		);
	}
	if (!DATA.tuitionFeedback) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "tuitionFeedbackError",
				error: "Feedback is required",
			})
		);
	}
	if (!DATA.tuitionFeedback) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "tuitionFeedbackError",
				error: "Tuition feedback is required",
			})
		);
	}
	if (DATA.safeguardingConcerns === true && !DATA.tuitionFeedback) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "tuitionFeedbackError",
				error: "Safeguarding concern is required",
			})
		);
	}
	return { valid, errors };
};

export const ValidateBranch = (DATA: IBranch) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Team name is required.",
			})
		);
	}
	// if (DATA.contactWebsite && !IsWebSite(DATA.contactWebsite)) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "websiteError",
	// 			error: "Please enter a valid website url.",
	// 		})
	// 	);
	// }
	// if (!DATA.address) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "addressLine1Error",
	// 			error: "Address Line 1 is required.",
	// 		})
	// 	);
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "postcodeError",
	// 			error: "Postcode is required.",
	// 		})
	// 	);
	// } else {
	// 	if (!DATA.address.addressLine1) {
	// 		valid = false;
	// 		errors.push(
	// 			new FieldValidaionModel({
	// 				name: "addressLine1Error",
	// 				error: "Address Line 1 is required.",
	// 			})
	// 		);
	// 	}
	// 	if (!DATA.address.postcode) {
	// 		valid = false;
	// 		errors.push(
	// 			new FieldValidaionModel({
	// 				name: "postcodeError",
	// 				error: "Postcode is required.",
	// 			})
	// 		);
	// 	}
	// }
	// if (DATA.contactWebsite && !IsWebSite(DATA.contactWebsite)) {
	// 	valid = false;
	// 	errors.push(
	// 		new FieldValidaionModel({
	// 			name: "websiteError",
	// 			error: "Please enter a valid website url.",
	// 		})
	// 	);
	// }
	return { valid, errors };
};

export const ValidateUmbrellaCreate = (DATA: IUmbrella) => {
	let valid = true,
		errors = [];
	if (!DATA.name) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "nameError",
				error: "Umbrella name is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateTargetCreate = (DATA: ITarget) => {
	let valid = true,
		errors = [];
	if (!DATA.start) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "startError",
				error: "Target coverage is required.",
			})
		);
	}
	if (!DATA.type) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "typeError",
				error: "Target type is required.",
			})
		);
	}
	if (!DATA.value) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "valueError",
				error: "Target value is required.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateFileUpload = (size: number, type: string, maxSize: number, acceptedFileTypes: string[]) => {
	let valid = true,
		errors = [];
	if (size && !ValidateFileSize(size, maxSize)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "fileSizeError",
				error: `The file size exceeds the maximum limit of ${maxSize}MB. Please reduce the file size or choose a different file and try again`,
			})
		);
	}
	if (type && !ValidateFileType(type, acceptedFileTypes)) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "fileTypeError",
				error: "The file type is not supported. Please upload a file in standard document or image formats (e.g., PDF, DOC, JPEG, PNG) and try again.",
			})
		);
	}
	return { valid, errors };
};

export const ValidateCandidateEnquiryCreate = (DATA: CandidateEnquiryInterface, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA.forename) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "forenameError",
				error: "First name is required.",
			})
		);
	}
	if (!DATA.surname) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "surnameError",
				error: "Surname is required.",
			})
		);
	}
	if (!DATA.email) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "emailError",
				error: "Email is required.",
			})
		);
	} else {
		if (!IsEmail(DATA.email)) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "emailError",
					error: "Please enter a valid email address.",
				})
			);
		}
	}
	if (!IsNonUk(formQuestions)) {
		if (!DATA.address) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "addressLine1Error",
					error: "Address Line 1 is required.",
				})
			);
			errors.push(
				new FieldValidaionModel({
					name: "postcodeError",
					error: "Postcode is required.",
				})
			);
		} else {
			if (!DATA.address.addressLine1) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "addressLine1Error",
						error: "Address Line 1 is required.",
					})
				);
			}
			if (!DATA.address.postcode) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "postcodeError",
						error: "Postcode is required.",
					})
				);
			}
		}
	} else {
		if (!DATA.address) {
			valid = false;
			errors.push(
				new FieldValidaionModel({
					name: "countryError",
					error: "Country is required.",
				})
			);
		} else {
			if (!DATA.address.country) {
				valid = false;
				errors.push(
					new FieldValidaionModel({
						name: "countryError",
						error: "Country is required.",
					})
				);
			}
		}
	}
	return { valid, errors };
};

export const ValidateCandidateInterviewConfirmation = (DATA: ICandidateInterviewQuestions, formQuestions: IFormQuestion[]) => {
	let valid = true,
		errors = [];
	if (!DATA?.candidateApprovalSignature?.trim() && !DATA?.candidateApprovalNote?.trim()) {
		valid = false;
		errors.push(
			new FieldValidaionModel({
				name: "candidateApprovalSignatureError",
				error: "Signature or approval note is required.",
			})
		);
	}
	return { valid, errors };
};
