import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import Modal from "react-modal";
import { toggleLoading, setAlert } from "../../../state/creators";
import { UserModel, UserLinkedClients, UserAccountStateModel, UserEmailModel, IUserClients } from "../../../_models/data.user.model";
import { ApiResultModel } from "../../../_models/api.result.model";
import { ValidateUser } from "../../../functions/validators";
import { CreateUser, UpdateUser, UpdateLinkedClients, ToggleAccountState, ResendActivationEmail, DeleteUser, DeleteUserAuthSession } from "../../../functions/http-functions/user";
import {
	PermissionOptionsClients,
	PermissionOptionsTimesheets,
	PermissionOptionsCandidates,
	PermissionOptionsBookings,
	PermissionOptionsMarketing,
	PermissionOptionsCustomer,
	PermissionOptionsStats,
	PermissionOptionsInvoice,
	PermissionOptionsPayment,
	PermissionOptionsComplianceManager,
	PermissionsAuditTrail,
	PermissionOptionsReporting,
	PermissionOptionsPlacements,
	PermissionOptionsStatsFiltering,
	PermissionOptionsStatsCommission,
	PermissionOptionsAvailabilityManager,
	UserRoleTypes,
	PermissionOptionsTags,
} from "../../../config/formData";
import Text from "../inputs/text";
import CheckboxGroup from "../inputs/checkboxGroup";
import FormError from "../errors/http";
import ConfirmModal from "../../modals/confirmModal";
import LinkClientsModal from "../../modals/linkClients";
import { Form, FormGroup, FormGroupLabel, FormGroupSection } from "../../../styles/forms";
import { ButtonList, SubmitButton, StandardButtonAnchor, StandardCancelAnchor } from "../../../styles/type/buttons";
import Row from "../../../styles/layout/row";
import Col from "../../../styles/layout/col";
import { ModalStyles, ModalStylesLarge } from "../../../styles/modals";
import { CandidateStatusBlockStyled } from "../../../styles/blocks";
import { PermissionsValidate } from "../../../functions/helpers";
import { Permissions } from "../../../_enums/permissions.enum";
import { UserInterface, UserMergedWithActivationSession } from "../../../_types/user";
import { AlertType } from "../../../state/reducers/setAlert";
import { EventTargetType } from "../../../_types/form";
import { ClientInterface } from "../../../_types/client";
import { IHttpResponse } from "../../../functions/http-functions/http";
import CommissionInput from "../inputs/commission";
import HelpModal from "../../modals/help.modal";
import { CommissionThresholdHelpText } from "../../../config/helpText";
import { Note } from "../../../styles/forms/inputs";
import { useAuth } from "../../../state/context/auth.store";
import moment from "moment";
import UserAddConfirmModal from "../../modals/userAddConfirmModal";
import { IBranch } from "../../../_models/data.branch.model";
import UserBranches from "./branches";
import UserTargets from "./targets";
import { ITarget } from "../../../_models/data.target.model";
import SelectInput from "../inputs/select";
import { Divider } from "../../../styles/content";
import { PopulatePermissionsBasedOnRole } from "../../../config/roles";
import { Warning } from "../errors/input";
import CheckboxInput from "../inputs/checkbox";

type Props = {
	user: UserInterface | UserMergedWithActivationSession;
	toggleLoading: (loading: boolean) => void;
	setAlert: (alert: AlertType) => void;
	signedInUser: UserInterface;
	authSession?: boolean;
	branches?: IBranch[];
	branchTotal?: number;
	targets?: ITarget[];
	targetTotal?: number;
	userClients?: IUserClients;
};

export enum UserRole {
	RecruitmentConsultant = "Recruitment Consultant",
	Compliance = "Compliance",
	SuperAdministrator = "Super Administrator",
	Manager = "Manager",
}

const UserForm: React.FC<Props> = ({ user, toggleLoading, setAlert, signedInUser: signedInUserFromStore, authSession, branches, branchTotal, targets, targetTotal, userClients }) => {
	const { auth } = useAuth();
	const [state, setState] = useState(new UserModel(user || {}));
	const [clients, setClients] = useState<string[]>(userClients?.clients ?? []);
	const [signedInUser, setSignedInUser] = useState<UserInterface>(signedInUserFromStore);
	const [error, setError] = useState<JSX.Element>(null);
	const [updating, isUpdating] = useState<boolean>(false);
	const [modalIsOpen, setIsOpen] = useState<boolean>(false);
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
	const [linkedModalIsOpen, setLinkedModalIsOpen] = useState<boolean>(false);
	const [activationSent, setActivationSent] = useState<string>(null);
	const [addConfirmModalIsOpen, setAddConfirmModalIsOpen] = useState<boolean>(false);
	const [endAuthModalIsOpen, setEndAuthModalIsOpen] = useState<boolean>(false);
	const [role, setRole] = useState<UserRole>(null);

	const router = useRouter();

	useEffect(() => {
		if (user) isUpdating(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (signedInUserFromStore !== signedInUser) {
			setSignedInUser((prevUser) => {
				if (prevUser !== signedInUserFromStore) {
					return signedInUserFromStore;
				} else return prevUser;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signedInUserFromStore]);

	const handleChange = (e: EventTargetType) => {
		const target = e.target;
		const { name, value, type, checked } = target;
		setState((prevState) => ({ ...prevState, [name]: type === "checkbox" ? checked : value }));
	};

	const handleRoleChange = ({ target: { name, value } }: EventTargetType) => {
		setState((prevState) => ({
			...prevState,
			permissions: PopulatePermissionsBasedOnRole(value as UserRole),
		}));
	};

	const handleSubmit = (e: MouseEvent) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			firstNameError: null,
			lastNameError: null,
			usernameError: null,
			emailError: null,
			positionError: null,
			commissionThresholdError: null,
		}));
		setError(null);
		const ValidateStep = ValidateUser({ ...state });
		if (ValidateStep.valid) {
			updating ? updateUser() : setAddConfirmModalIsOpen(true);
		} else {
			for (let i = 0, item; !!(item = ValidateStep.errors[i++]); ) {
				setState((prevState) => ({
					...prevState,
					[item.name]: item.error,
				}));
			}
			setError(<FormError errors={["There were one or more errors validating your submission. The fields have been highlighted above."]} />);
		}
	};

	const addUser = (sendActivation?: boolean) => {
		toggleLoading(true);
		CreateUser(auth.token, {
			...new UserModel({ ...state }),
			sendActivation,
		})
			.then((res) => {
				const Result = new ApiResultModel(res.data);
				setAlert({ message: "User added successfully." });
				router.push(`/account/me/subscription/users`);
			})
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setError(<FormError errors={[ErrorResult.message]} />);
			})
			.then(() => {
				setAddConfirmModalIsOpen(false);
				toggleLoading(false);
			});
	};

	const updateUser = () => {
		toggleLoading(true);
		UpdateUser(auth.token, new UserModel({ ...state }), user._id)
			.then((res) => setAlert({ message: "User updated successfully." }))
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setError(<FormError errors={[ErrorResult.message]} />);
				setAlert({ type: "error", message: ErrorResult.message });
			})
			.then(() => toggleLoading(false));
	};

	const toggleAccountDisable = () => {
		const accountState = !state.disabled;
		toggleLoading(true);
		ToggleAccountState(auth.token, new UserAccountStateModel({ disabled: accountState }), user._id)
			.then((res) => {
				setAlert({
					message: "User account status updated successfully.",
				});
				setState((prevState) => ({
					...prevState,
					disabled: accountState,
				}));
				setIsOpen(false);
			})
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setError(<FormError errors={[ErrorResult.message]} />);
				setAlert({ type: "error", message: ErrorResult.message });
			})
			.then(() => toggleLoading(false));
	};

	const toggleAccountDeletion = async () => {
		try {
			toggleLoading(true);
			await DeleteUser(auth.token, user._id);
			setAlert({
				type: "success",
				message: "User deleted successfully.",
			});
			router.push(`/account/me/subscription/users`);
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setError(<FormError errors={[ErrorResult.message]} />);
			setAlert({ type: "error", message: ErrorResult.message });
		} finally {
			toggleLoading(false);
		}
	};

	const linkClients = (clients: ClientInterface[]) => {
		toggleLoading(true);
		UpdateLinkedClients(auth.token, new UserLinkedClients({ clients }), user._id)
			.then((res) => {
				setClients(res.data?.clients ?? []);
				setAlert({ message: "User clients updated successfully." });
			})
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setError(<FormError errors={[ErrorResult.message]} />);
				setAlert({ type: "error", message: ErrorResult.message });
			})
			.then(() => {
				setLinkedModalIsOpen(false);
				toggleLoading(false);
			});
	};

	const resendActivationEmail = () => {
		toggleLoading(true);
		ResendActivationEmail(auth.token, new UserEmailModel({ email: state.email }))
			.then((res) => {
				setActivationSent(moment().format("YYYY-MM-DDTHH:mm:ss"));
				setAlert({
					message: "User activation email sent successfully.",
				});
				toggleLoading(false);
				setTimeout(() => {
					router.reload();
				}, 10);
			})
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setError(<FormError errors={[ErrorResult.message]} />);
				setAlert({ type: "error", message: ErrorResult.message });
			})
			.then(() => toggleLoading(false));
	};

	const revokeAuthentication = async () => {
		try {
			await DeleteUserAuthSession(auth.token, user._id);
			setAlert({
				message: "User authenticated session revoked successfully.",
			});
			toggleLoading(false);
			setTimeout(() => {
				router.reload();
			}, 10);
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setError(<FormError errors={[ErrorResult.message]} />);
			setAlert({ type: "error", message: ErrorResult.message });
		} finally {
			setEndAuthModalIsOpen(false);
			toggleLoading(false);
		}
	};

	return (
		<>
			<Form>
				<Row>
					<Col span={10} span1024={12}>
						{user && state.activationCode ? (
							<CandidateStatusBlockStyled status={1}>
								<p>
									User account has not been activated
									{(user as UserMergedWithActivationSession).userActivationSession
										? `. Last sent ${moment(activationSent ?? (user as UserMergedWithActivationSession).userActivationSession.updatedAt).format("DD/MM/YYYY HH:mm")}. `
										: ". No active activation request. "}
									<a onClick={resendActivationEmail}>Resend activation request</a>
								</p>
							</CandidateStatusBlockStyled>
						) : null}
						{user && state.disabled ? (
							<CandidateStatusBlockStyled status={3}>
								<p>User account is disabled.</p>
							</CandidateStatusBlockStyled>
						) : null}
						<FormGroup active={true}>
							<FormGroupLabel active={true}>Account Information</FormGroupLabel>
							<FormGroupSection active={true}>
								<Row>
									<Col span={6} span768={12}>
										<Text name="firstName" label="First Name" value={state.firstName} onChange={handleChange} error={state.firstNameError} required={true} />
									</Col>
									<Col span={6} span768={12}>
										<Text name="lastName" label="Last Name" value={state.lastName} onChange={handleChange} error={state.lastNameError} required={true} />
									</Col>
									<Col span={6} span768={12}>
										<Text name="email" label="Email" value={state.email} onChange={handleChange} error={state.emailError} required={true} type="email" />
									</Col>
									<Col span={6} span768={12}>
										<Text name="username" label="Username" value={state.username} onChange={handleChange} error={state.usernameError} required={true} />
									</Col>
									<Col span={6} span768={12}>
										<Text name="phone" label="Phone" value={state.phone} onChange={handleChange} type="tel" />
									</Col>
									<Col span={6} span768={12}>
										<Text name="position" label="Job Title" value={state.position} onChange={handleChange} error={state.positionError} required={true} />
									</Col>
								</Row>
								<Row>
									<Col span={6} span768={12}>
										<Text name="lea" label="LEA" note="This is used to match the users to various clients" value={state.lea} onChange={handleChange} />
									</Col>
								</Row>
								<Row>
									<Col span={6} span768={12}>
										<CheckboxInput
											label="Default Candidate Watcher"
											name="defaultCandidateWatcher"
											onChange={handleChange}
											value={state.defaultCandidateWatcher}
											note="Ticking this will automatically add this user as a watcher to manually new candidate records created within Edun."
										/>
									</Col>
								</Row>
							</FormGroupSection>
						</FormGroup>
						{PermissionsValidate(auth.token, [Permissions.CanManageCustomer]) ? (
							<div>
								<FormGroup active={true}>
									<FormGroupLabel active={true}>Commission Threshold</FormGroupLabel>
									<FormGroupSection active={true}>
										<Warning message="Be careful when alterning existing commission values." />
										<Warning
											pushBottom
											message="This will affect all newly created bookings for this user. Any already existing bookings for this user will use the commission values that were present at the point that booking was created. If you wish to update previously added bookings please get in touch with Edun support."
										/>
										<Note pushBottom>Any commission values stated within Edun are representative, always double check before paying out.</Note>
										<CommissionInput
											value={state.commissionThreshold}
											name="commissionThreshold"
											label="Threshold/s (£)"
											onChange={handleChange}
											error={state.commissionThresholdError}
											required={true}
											help={{
												modalTitle: "Commission Threshold",
												modalContent: <HelpModal title="Commission Threshold" content={CommissionThresholdHelpText} />,
											}}
											showCommissionType={false}
										/>
									</FormGroupSection>
								</FormGroup>
							</div>
						) : null}
						{PermissionsValidate(auth.token, [Permissions.CanEditCustomerUsers]) ? (
							<FormGroup active={true}>
								<FormGroupLabel active={true}>Account Permissions</FormGroupLabel>
								<FormGroupSection active={true}>
									<Note>Alterning permissions affects what users within Edun can do. Be careful when selecting permissions for users.</Note>
									<Note pushBottom>By default users will receive no permissions.</Note>
									<Row>
										<Col span={6}>
											<SelectInput
												note="Select a role below to auto-populate relevant permissions. Once selected permissions can still be amended individually for more granular control"
												label="Role"
												name="role"
												value={role}
												options={UserRoleTypes}
												onChange={handleRoleChange}
											/>
										</Col>
									</Row>
									<Divider />
									<CheckboxGroup
										note="Global Edun permissions. The below permissions should be reserved for admin users only."
										name="permissions"
										label="Admin / Business Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsCustomer}
										hasDesc
									/>
									<CheckboxGroup name="permissions" label="Client Permissions." value={state.permissions} onChange={handleChange} options={PermissionOptionsClients} hasDesc />
									<CheckboxGroup name="permissions" label="Candidate Permissions." value={state.permissions} onChange={handleChange} options={PermissionOptionsCandidates} hasDesc />
									<CheckboxGroup
										note="The below set of permissions concerns compliance and various actions related to handling compliance within Edun."
										name="permissions"
										label="Compliance."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsComplianceManager}
										hasDesc
									/>
									<CheckboxGroup name="permissions" label="Bookings Permissions." value={state.permissions} onChange={handleChange} options={PermissionOptionsBookings} hasDesc />
									<CheckboxGroup
										name="permissions"
										label="Vacancy Manager Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsPlacements}
										hasDesc
									/>
									<CheckboxGroup
										name="permissions"
										label="Availability Manager Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsAvailabilityManager}
										hasDesc
									/>
									<CheckboxGroup
										note="If the user can manage timesheets they may need to be able to create and edit timesheets. These permissions can be configured below."
										name="permissions"
										label="Timesheet Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsTimesheets}
										hasDesc
									/>
									<CheckboxGroup
										note="If the user can create marketing campaigns and templates. These permissions can be configured below."
										name="permissions"
										label="Marketing Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsMarketing}
										hasDesc
									/>
									<CheckboxGroup name="permissions" label="Integrations Permissions." value={state.permissions} onChange={handleChange} options={PermissionOptionsInvoice} hasDesc />
									<CheckboxGroup
										note="If the user can manage payments they may need to be able to create and edit payment run documents. These permissions can be configured below."
										name="permissions"
										label="Payment Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsPayment}
										hasDesc
									/>
									<CheckboxGroup name="permissions" label="Tag Permissions." value={state.permissions} onChange={handleChange} options={PermissionOptionsTags} hasDesc />
									<CheckboxGroup
										note="Event logs visibility."
										name="permissions"
										label="Event Log Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionsAuditTrail}
										hasDesc
									/>
									<CheckboxGroup
										note="Visibiliy of booking stats, profit margins, commission and who can see other company users stats can be configured below."
										name="permissions"
										label="Reporting Permissions."
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsStats}
										hasDesc
									/>
									<CheckboxGroup
										note="Reporting data filtering and visibility, exports"
										name="permissions"
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsStatsFiltering}
										hasDesc
									/>
									<CheckboxGroup
										note="Commission visibility"
										name="permissions"
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsStatsCommission}
										hasDesc
									/>
									<CheckboxGroup
										note="The below permissions provide access to the reporting dashboard. Users can be provided further permissions for viewing company level analytics or just their own."
										name="permissions"
										value={state.permissions}
										onChange={handleChange}
										options={PermissionOptionsReporting}
										hasDesc
									/>
								</FormGroupSection>
							</FormGroup>
						) : null}
					</Col>
					<Col span={2} span1024={12}>
						<div>
							<ButtonList justify="flex-end" column={true} pushBottom={error ? true : user ? true : false}>
								<SubmitButton onClick={handleSubmit}>{updating ? "Update" : "Add"} User</SubmitButton>
							</ButtonList>
							{error}
						</div>
					</Col>
				</Row>
				{user ? (
					<FormGroup active={true}>
						<FormGroupLabel active={true}>User Targets</FormGroupLabel>
						<FormGroupSection active={true}>
							<UserTargets user={user} targets={targets} total={targetTotal} />
						</FormGroupSection>
					</FormGroup>
				) : null}
				{user ? (
					<FormGroup active={true}>
						<FormGroupLabel active={true}>Assigned Teams</FormGroupLabel>
						<FormGroupSection active={true}>
							<UserBranches user={user} branches={branches} total={branchTotal} />
						</FormGroupSection>
					</FormGroup>
				) : null}
				{user && PermissionsValidate(auth.token, [Permissions.CanEditCustomerUsers]) && !state.permissions.find((permission: number) => permission == Permissions.CanViewAllClients) ? (
					<FormGroup active={true}>
						<FormGroupLabel active={true}>Linked Clients</FormGroupLabel>
						<FormGroupSection active={true}>
							<p>If the user is not authorized or permitted to view all clients, then you can select individual clients that the user has access to within Edun.</p>
							<p>User is currently linked to {clients.length} clients.</p>
							<ButtonList justify="flex-start">
								<StandardButtonAnchor onClick={() => setLinkedModalIsOpen(true)}>Manage Linked Clients</StandardButtonAnchor>
							</ButtonList>
						</FormGroupSection>
					</FormGroup>
				) : null}
				{user && PermissionsValidate(auth.token, [Permissions.CanEditCustomerUsers]) && signedInUser?._id !== user._id ? (
					<>
						<FormGroup active={true}>
							<FormGroupLabel active={true}>Account Session</FormGroupLabel>
							<FormGroupSection active={true}>
								{authSession ? (
									<>
										<p>
											The user account currently has an active authenticated session. If you would like to revoke this session and therefore make the user have to authenticate
											again you can do so below.
										</p>
										<ButtonList justify="flex-start" pushBottom={error ? true : false}>
											<StandardButtonAnchor onClick={() => setEndAuthModalIsOpen(true)}>Revoke Authenticated Session</StandardButtonAnchor>
										</ButtonList>
									</>
								) : (
									<p>The user account currently has no active authenticated session.</p>
								)}
							</FormGroupSection>
						</FormGroup>
					</>
				) : null}
				{user && PermissionsValidate(auth.token, [Permissions.CanEditCustomerUsers]) ? (
					<>
						<FormGroup active={true}>
							<FormGroupLabel active={true}>Account Status</FormGroupLabel>
							<FormGroupSection active={true}>
								{signedInUser?._id === user._id ? (
									<>
										<p>
											You can not deactivate your own account from here. To deactivate your account have another member of your team with the correct permissions action this or
											contact <a href="mailto:info@helloedun.co.uk">info@helloedun.co.uk</a> for account deactivation.
										</p>
									</>
								) : (
									<>
										<p>
											The user account is currrently {state.disabled ? "disabled" : "active"}. If you would like to {state.disabled ? "enable" : "disable"} the account click the
											button below.
										</p>
										<p>
											{state.disabled
												? "Enabling the account will take one of your available user licences if there is one available."
												: "Disabling the account will free up one of your user licences."}
										</p>
										<ButtonList justify="flex-start" pushBottom={error ? true : false}>
											<StandardButtonAnchor onClick={() => setIsOpen(true)}>{state.disabled ? "Enable" : "Disable"} User Account</StandardButtonAnchor>
										</ButtonList>
									</>
								)}
							</FormGroupSection>
						</FormGroup>
						<FormGroup active={true}>
							<FormGroupLabel active danger>
								Delete Account
							</FormGroupLabel>
							<FormGroupSection active={true}>
								{signedInUser?._id === user._id ? (
									<>
										<p>
											You can not delete your own account from here. To remove your account have another member of your team with the correct permissions action this or contact{" "}
											<a href="mailto:info@helloedun.co.uk">info@helloedun.co.uk</a> for account deactivation.
										</p>
									</>
								) : (
									<>
										<p>Deleting the user account is an irreversible process.</p>
										<ButtonList justify="flex-start" pushBottom={error ? true : false}>
											<StandardCancelAnchor onClick={() => setDeleteModalIsOpen(true)}>Delete User Account</StandardCancelAnchor>
										</ButtonList>
									</>
								)}
							</FormGroupSection>
						</FormGroup>
					</>
				) : null}
			</Form>
			<Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} style={ModalStyles} contentLabel="" shouldCloseOnOverlayClick={true} ariaHideApp={false}>
				<ConfirmModal
					title={`Please confirm you would like to ${state.disabled ? "enable" : "disable"} the user account?`}
					content={
						state.disabled
							? "Enabling the account will take one of your available user licences if there is one available."
							: "Disabling the account will free up one of your user licences."
					}
					cancelButtonText="Cancel"
					confirmButtonText={state.disabled ? "Enable Account" : "Disable Account"}
					confirmButtonAction={toggleAccountDisable}
					cancelButtonAction={() => setIsOpen(false)}
				/>
			</Modal>
			<Modal
				isOpen={deleteModalIsOpen}
				onRequestClose={() => setDeleteModalIsOpen(false)}
				style={ModalStyles}
				contentLabel="Delete User Account"
				shouldCloseOnOverlayClick={true}
				ariaHideApp={false}
			>
				<ConfirmModal
					title={`Please confirm you would like to delete the user account?`}
					content="Deleting the user account is an irreversible process."
					cancelButtonText="Cancel"
					confirmButtonText="Delete Account"
					confirmButtonAction={toggleAccountDeletion}
					cancelButtonAction={() => setDeleteModalIsOpen(false)}
				/>
			</Modal>
			{endAuthModalIsOpen ? (
				<Modal
					isOpen={endAuthModalIsOpen}
					onRequestClose={() => setEndAuthModalIsOpen(false)}
					style={ModalStyles}
					contentLabel="Revoke Authentication Session"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
				>
					<ConfirmModal
						title={`Please confirm you would like to revoke the users authentication session?`}
						content="This will required the user to log back in to their Edun account."
						cancelButtonText="Cancel"
						confirmButtonText="Revoke Authentication"
						confirmButtonAction={revokeAuthentication}
						cancelButtonAction={() => setEndAuthModalIsOpen(false)}
					/>
				</Modal>
			) : null}
			{addConfirmModalIsOpen ? (
				<Modal
					isOpen={addConfirmModalIsOpen}
					onRequestClose={() => setAddConfirmModalIsOpen(false)}
					style={ModalStyles}
					contentLabel="Create User Account"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
				>
					<UserAddConfirmModal
						title={`Please confirm you would like to create the user account?`}
						content="Creating a new user will take up 1 free user licence from your business subscription. Please confirm you would like to create this user account. User accounts can be de-activated once created."
						cancelButtonText="Cancel"
						confirmButtonText="Create Account"
						confirmButtonAction={(sendActivation) => {
							addUser(sendActivation);
						}}
						cancelButtonAction={() => setAddConfirmModalIsOpen(false)}
					/>
				</Modal>
			) : null}
			<Modal isOpen={linkedModalIsOpen} onRequestClose={() => setLinkedModalIsOpen(false)} style={ModalStylesLarge} contentLabel="" shouldCloseOnOverlayClick={true} ariaHideApp={false}>
				<LinkClientsModal
					clients={clients}
					linkClients={linkClients}
					closeModal={() => setLinkedModalIsOpen(false)}
					user={`${state.firstName ? state.firstName : ""} ${state.lastName ? state.lastName : ""}`}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		signedInUser: state.setUser.user,
	};
};

const mapDispatchToProps = {
	toggleLoading,
	setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
